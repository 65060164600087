export const Cadastro = ({ data, setData, optionsUndadm, insertOrUpdate }) => {
  return (
    <div className="row">
      <div className="col-12">
        <div className="col-12 row">
          <div className="col-3 col-md-2">
            <span className="col-form-label">Cód. da Resposta</span>
          </div>
          <div className="col-9 col-md-10">
            <span className="col-form-label">Descrição da Resposta</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3 col-md-2">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              value={data?.id || ''}
              disabled
            />
          </div>
          <div className="col-9 col-md-10">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              value={data?.descricao || ''}
              onChange={(e) => setData({ ...data, descricao: e.target.value })}
              disabled={insertOrUpdate === 'read' && true}
              maxLength={100}
              style={{ textTransform: 'uppercase' }}
            />
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="col-12 row">
          <div className="col-6 col-md-4">
            <span className="col-form-label">Tipo Resposta</span>
          </div>
          <div className="col-6 col-md-4">
            <span className="col-form-label">Tipo Resposta Subjetiva</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6 col-md-4">
            <select
              className="form-select form-select-sm border border-dark"
              value={data?.tipoResposta || ''}
              onChange={(e) =>
                setData({ ...data, tipoResposta: e.target.value })
              }
              disabled={insertOrUpdate === 'read' && true}
            >
              <option defaultValue></option>
              <option value="OBJET">OBJETIVA</option>
              <option value="SUBJE">SUBJETIVA</option>
            </select>
          </div>
          <div className="col-6 col-md-4">
            <select
              className="form-select form-select-sm border border-dark"
              value={data?.tipoRespostaSub || ''}
              onChange={(e) =>
                setData({ ...data, tipoRespostaSub: e.target.value })
              }
              disabled={insertOrUpdate === 'read' && true}
            >
              <option defaultValue></option>
              <option value="RSBST">ALFA NUMÉRICA</option>
              <option value="RSBNU">NUMÉRICA</option>
            </select>
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="col-12 row">
          <div className="col-6 col-md-3">
            <span className="col-form-label">Usuário</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6 col-md-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              value={data?.usuario || ''}
              disabled
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-6 col-md-3">
            <span className="col-form-label">Dt. Inclusão</span>
          </div>
          <div className="col-6 col-md-3">
            <span className="col-form-label">Hr. Inclusão</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6 col-md-3">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              value={data?.dataInclusao || ''}
              disabled
            />
          </div>
          <div className="col-6 col-md-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              value={data?.horaInclusao || ''}
              disabled
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-6 col-md-3">
            <span className="col-form-label">Dt. Atualização</span>
          </div>
          <div className="col-6 col-md-3">
            <span className="col-form-label">Hr. Atualização</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6 col-md-3">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              value={data?.dataAtualizacao || ''}
              disabled
            />
          </div>
          <div className="col-6 col-md-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              value={data?.horaAtualizacao || ''}
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  );
};
