import {
  handleFirstPage,
  handleLastPage,
  handlePageClick,
  handleRowClick
} from '../../functions';

const BlcTitle = (props) => {
  return (
    <th scope="col">
      <div className="d-flex justify-content-between align-items-center">
        <span style={{ fontSize: props.tamSubTexto }}>{props.title}</span>
      </div>
    </th>
  );
};

export const GridQuestionario = (props) => {
  const campos = [
    { title: 'Cód. do Ambiente (Quest.)' },
    { title: 'Descrição do Ambiente (Quest.)' }
  ];

  const camposPergunta = [
    { title: 'Cód. da Pergunta' },
    { title: 'Descrição da Pergunta' }
  ];

  return (
    <div>
      <div className="nav nav-tabs">
        <p
          className={`nav-link ${
            props.activeTabQuesPer === 1 ? 'text-primary active' : 'text-dark'
          }`}
          onClick={() => {
            props.setActiveTabQuesPer(1);
          }}
        >
          Escolher Ambiente
        </p>
        <p
          className={`nav-link ${
            props.activeTabQuesPer === 2 ? 'text-primary active' : 'text-dark'
          }`}
          onClick={() => {
            props.setActiveTabQuesPer(2);
          }}
        >
          Escolher Pergunta
        </p>
      </div>

      <div className="tab-content">
        <div
          className={`tab-pane ${props.activeTabQuesPer === 1 ? 'active' : ''}`}
          id="tab1"
        >
          <table
            className="table table-striped table-hover table-bordered table-sm align-middle"
            style={{ height: '100%' }}
          >
            <thead>
              <tr className="table-secondary">
                {campos.map((campo, index) => (
                  <BlcTitle
                    key={index}
                    title={campo.title}
                    tamTexto={props.tamSubTexto}
                  />
                ))}
                <th
                  scope="col"
                  style={{ fontSize: props.tamTexto, width: '80px' }}
                ></th>
              </tr>
            </thead>
            <tbody>
              {props.filteredData?.map((item, index) => (
                <tr
                  key={index}
                  className={props.selectedRow === index ? 'bg-info' : ''}
                  onClick={() => handleRowClick(index, props.setSelectedRow)}
                >
                  <td scope="row" style={{ fontSize: props.tamSubTexto }}>
                    {item.qap_seqque}
                  </td>
                  <td style={{ fontSize: props.tamSubTexto }}>
                    {item.que_descri}
                  </td>
                  <td>
                    <div>
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-sm"
                        onClick={async () => {
                          props.setData({
                            ...props.data,
                            codigoQuestionario: item.qap_seqque,
                            questionario: item.que_descri
                          });

                          props.setTitle(
                            `${props.title} | Ambiente: ${item.que_descri}`
                          );

                          props.setActiveTabCadastro(4);
                          props.setTabReturn(2);
                          props.setIsPerguntaOrAmbiente('ambiente');
                        }}
                        style={{ fontSize: props.tamSubTexto }}
                      >
                        {props.insertOrUpdate === 'read' ? (
                          <i className="fa-solid fa-eye"></i>
                        ) : (
                          <i className="fas fa-edit"></i>
                        )}
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="4" className="align-middle">
                  <nav className="d-flex align-items-center justify-content-center">
                    <ul className="pagination">
                      <li className="page-item">
                        <p
                          className="page-link"
                          aria-label="PreviousPage"
                          onClick={() =>
                            handleFirstPage(
                              props.setCurrentPage,
                              props.setSelectedRow
                            )
                          }
                        >
                          <span
                            aria-hidden="true"
                            style={{ fontSize: props.tamTexto }}
                          >
                            &laquo;
                          </span>
                        </p>
                      </li>
                      <li className="page-item">
                        <p
                          className="page-link"
                          aria-label="PreviousIten"
                          onClick={() =>
                            handlePageClick(
                              props.currentPage - 1,
                              props.pageCount,
                              props.setCurrentPage,
                              props.setSelectedRow
                            )
                          }
                        >
                          <span
                            aria-hidden="true"
                            style={{ fontSize: props.tamTexto }}
                          >
                            &lt;
                          </span>
                        </p>
                      </li>
                      {props.displayedPageNumbers.map((pageNumber) => (
                        <li
                          key={pageNumber}
                          className={`page-item ${
                            pageNumber === props.currentPage ? 'active' : ''
                          }`}
                          style={{ fontSize: props.tamTexto }}
                        >
                          <button
                            className="page-link"
                            onClick={() =>
                              handlePageClick(
                                pageNumber,
                                props.pageCount,
                                props.setCurrentPage,
                                props.setSelectedRow
                              )
                            }
                          >
                            {pageNumber + 1}
                          </button>
                        </li>
                      ))}
                      <li className="page-item">
                        <p
                          className="page-link"
                          aria-label="NextIten"
                          onClick={() =>
                            handlePageClick(
                              props.currentPage + 1,
                              props.pageCount,
                              props.setCurrentPage,
                              props.setSelectedRow
                            )
                          }
                        >
                          <span
                            aria-hidden="true"
                            style={{ fontSize: props.tamTexto }}
                          >
                            &gt;
                          </span>
                        </p>
                      </li>
                      <li className="page-item">
                        <p
                          className="page-link"
                          aria-label="NextPage"
                          onClick={() =>
                            handleLastPage(
                              props.setCurrentPage,
                              props.setSelectedRow,
                              props.pageCount
                            )
                          }
                        >
                          <span
                            aria-hidden="true"
                            style={{ fontSize: props.tamTexto }}
                          >
                            &raquo;
                          </span>
                        </p>
                      </li>
                    </ul>
                    <div className="d-flex flex-column">
                      <p
                        className="text-muted ms-2"
                        style={{ fontSize: props.tamTexto }}
                      >
                        Quantidade de Registros: {props.contQuestionario}
                      </p>
                    </div>
                  </nav>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>

        <div
          className={`tab-pane ${props.activeTabQuesPer === 2 ? 'active' : ''}`}
          id="tab2"
        >
          <table
            className="table table-striped table-hover table-bordered table-sm align-middle"
            style={{ height: '100%' }}
          >
            <thead>
              <tr className="table-secondary">
                {camposPergunta.map((campo, index) => (
                  <BlcTitle
                    key={index}
                    title={campo.title}
                    tamTexto={props.tamSubTexto}
                  />
                ))}
                <th
                  scope="col"
                  style={{ fontSize: props.tamTexto, width: '80px' }}
                ></th>
              </tr>
            </thead>
            <tbody>
              {props.filteredDataEscolherPergunta?.map((item, index) => (
                <tr
                  key={index}
                  className={
                    props.selectedRowEscolherPergunta === index ? 'bg-info' : ''
                  }
                  onClick={() =>
                    handleRowClick(index, props.setSelectedRowEscolherPergunta)
                  }
                >
                  <td scope="row" style={{ fontSize: props.tamSubTexto }}>
                    {item.psa_codper}
                  </td>
                  <td style={{ fontSize: props.tamSubTexto }}>
                    {item.per_descri}
                  </td>
                  <td>
                    <div>
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-sm"
                        onClick={async () => {
                          await props.setData({
                            ...props.data,
                            codigoPergunta: item.psa_codper,
                            pergunta: item.per_descri
                          });

                          // props.setTitle(
                          //   `${props.title} | Ambiente: ${item.que_descri}`
                          // );

                          props.setActiveTabCadastro(4);
                          props.setTabReturn(2);
                          props.setIsPerguntaOrAmbiente('pergunta');
                          props.setPerguntaSelecionada(item.per_descri);
                          await props.handleAmbienteExistentes(item.psa_codper);
                        }}
                        style={{ fontSize: props.tamSubTexto }}
                      >
                        {props.insertOrUpdate === 'read' ? (
                          <i className="fa-solid fa-eye"></i>
                        ) : (
                          <i className="fas fa-edit"></i>
                        )}
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="4" className="align-middle">
                  <nav className="d-flex align-items-center justify-content-center">
                    <ul className="pagination">
                      <li className="page-item">
                        <p
                          className="page-link"
                          aria-label="PreviousPage"
                          onClick={() =>
                            handleFirstPage(
                              props.setCurrentPageEscolherPergunta,
                              props.setSelectedRowEscolherPergunta
                            )
                          }
                        >
                          <span
                            aria-hidden="true"
                            style={{ fontSize: props.tamTexto }}
                          >
                            &laquo;
                          </span>
                        </p>
                      </li>
                      <li className="page-item">
                        <p
                          className="page-link"
                          aria-label="PreviousIten"
                          onClick={() =>
                            handlePageClick(
                              props.currentPageEscolherPergunta - 1,
                              props.pageCountEscolherPergunta,
                              props.setCurrentPageEscolherPergunta,
                              props.setSelectedRowEscolherPergunta
                            )
                          }
                        >
                          <span
                            aria-hidden="true"
                            style={{ fontSize: props.tamTexto }}
                          >
                            &lt;
                          </span>
                        </p>
                      </li>
                      {props.displayedPageNumbersEscolherPergunta.map(
                        (pageNumber) => (
                          <li
                            key={pageNumber}
                            className={`page-item ${
                              pageNumber === props.currentPageEscolherPergunta
                                ? 'active'
                                : ''
                            }`}
                            style={{ fontSize: props.tamTexto }}
                          >
                            <button
                              className="page-link"
                              onClick={() =>
                                handlePageClick(
                                  pageNumber,
                                  props.pageCountEscolherPergunta,
                                  props.setCurrentPageEscolherPergunta,
                                  props.setSelectedRowEscolherPergunta
                                )
                              }
                            >
                              {pageNumber + 1}
                            </button>
                          </li>
                        )
                      )}
                      <li className="page-item">
                        <p
                          className="page-link"
                          aria-label="NextIten"
                          onClick={() =>
                            handlePageClick(
                              props.currentPageEscolherPergunta + 1,
                              props.pageCountEscolherPergunta,
                              props.setCurrentPageEscolherPergunta,
                              props.setSelectedRowEscolherPergunta
                            )
                          }
                        >
                          <span
                            aria-hidden="true"
                            style={{ fontSize: props.tamTexto }}
                          >
                            &gt;
                          </span>
                        </p>
                      </li>
                      <li className="page-item">
                        <p
                          className="page-link"
                          aria-label="NextPage"
                          onClick={() =>
                            handleLastPage(
                              props.setCurrentPageEscolherPergunta,
                              props.setSelectedRowEscolherPergunta,
                              props.pageCountEscolherPergunta
                            )
                          }
                        >
                          <span
                            aria-hidden="true"
                            style={{ fontSize: props.tamTexto }}
                          >
                            &raquo;
                          </span>
                        </p>
                      </li>
                    </ul>
                    <div className="d-flex flex-column">
                      <p
                        className="text-muted ms-2"
                        style={{ fontSize: props.tamTexto }}
                      >
                        Quantidade de Registros: {props.contEscolherPergunta}
                      </p>
                    </div>
                  </nav>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
};
