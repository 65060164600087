export const CadCopia = (props) => {
  return (
    <div className="row">
      <div className="col-12 row">
        <div className="col-sm-12 col-md-6 col-lg-6">
          <span className="col-form-label">Ambiente Novo</span>
          <select
            className="form-select form-select-sm border border-dark"
            name="questionario"
            value={props.data?.questionarioNovo || ''}
            onChange={(e) => {
              const filter = props.optionsQuestionarioNovoCopia?.filter(
                (option) => {
                  return option.descricao === e.target.value;
                }
              );

              props.setData({
                ...props.data,
                codigoQuestionarioNovo: filter[0]?.codigo || '',
                questionarioNovo: e.target.value
              });
            }}
          >
            <option defaultValue></option>
            {props.optionsQuestionarioNovoCopia
              ?.sort((a, b) => a.descricao.localeCompare(b.descricao))
              .map((option, index) => (
                <option key={index} value={option.descricao}>
                  {option.descricao}
                </option>
              ))}
          </select>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6">
          <span className="col-form-label">Copiar do Ambiente</span>
          <select
            className="form-select form-select-sm border border-dark"
            name="questionario"
            value={props.data?.questionario || ''}
            onChange={(e) => {
              const filter = props.optionsQuestionario?.filter((option) => {
                return option.descricao === e.target.value;
              });

              props.setData({
                ...props.data,
                codigoQuestionario: filter[0]?.codigo || '',
                questionario: e.target.value
              });
            }}
          >
            <option defaultValue></option>
            {props.optionsQuestionario
              ?.sort((a, b) => a.descricao.localeCompare(b.descricao))
              .map((option, index) => (
                <option key={index} value={option.descricao}>
                  {option.descricao}
                </option>
              ))}
          </select>
        </div>
      </div>
    </div>
  );
};
