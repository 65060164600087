import {
  ApiDelete,
  ApiGet,
  ApiPostNew,
  ApiPut
} from '../../../services/apiService';

import Swal from 'sweetalert2';

export async function questionarioAreaPerguntaPost(data) {
  try {
    const response = await ApiPostNew('/v1/questionarioAreaPergunta', data);
    if (response.data.mensagem.msg.slice(0, 3) === '100') {
      await Swal.fire({
        title: 'Registro salvo com sucesso!',
        html: `Pergunta: ${data.perguntaDescricao}`,
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok'
      });
      return true;
    }
    await Swal.fire({
      title: 'Erro ao salvar!',
      html: `Pergunta: ${data.perguntaDescricao} <br> Erro: ${response.data.mensagem.msg}`,
      icon: 'error',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok'
    });
    return false;
  } catch (err) {
    console.log(err);
  }
}

export async function questionarioAreaPerguntaCopia(data, res) {
  let dataCompleto = res;

  for (let i = 0; i < res.length; i++) {
    const body = {
      id: data.codigoQuestionarioNovo,
      area: res[i].qap_codare,
      subarea: res[i].qap_codsar,
      pergunta: res[i].qap_codper,
      usuario: localStorage.getItem('sessionUsuario'),
      ordemArea: res[i].qap_ordare,
      ordemSubarea: res[i].qap_ordsar,
      ordemPergunta: res[i].qap_ordper,
      descricaoPergunta: res[i].per_descri
    };

    try {
      const response = await ApiPostNew('/v1/questionarioAreaPergunta', body);
      dataCompleto[i] = {
        ...dataCompleto[i],
        response: response.data.mensagem.msg
      };
    } catch (err) {
      console.log(err);
    }
  }

  let erros = [];
  for (let i = 0; i < dataCompleto.length; i++) {
    if (dataCompleto[i].response.slice(0, 3) !== '100') {
      erros.push(
        `Pergunta: ${dataCompleto[i].per_descri} - Erro: ${dataCompleto[i].response}`
      );
    }
  }

  if (erros.length === 0) {
    await Swal.fire({
      title: 'Sucesso!',
      html: `Todos os itens foram salvos com sucesso!`,
      icon: 'success',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok'
    });
  } else {
    await Swal.fire({
      title: 'Erro ao salvar!',
      html: `Algumas perguntas tiveram erro:<br>${erros.join('<br>')}`,
      icon: 'error',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok'
    });
  }
}

export async function questionarioAreaPerguntaPostVarios(data) {
  let dataCompleto = data;

  for (let i = 0; i < data.perguntas.length; i++) {
    const body = {
      id: data.codigoQuestionario,
      area: data.codigoArea,
      subarea: data.codigoSubarea,
      pergunta: data.perguntas[i],
      usuario: localStorage.getItem('sessionUsuario')
      // ordemArea: res[i].qap_ordare,
      // ordemSubarea: res[i].qap_ordsar,
      // ordemPergunta: res[i].qap_ordper,
      // descricaoPergunta: res[i].per_descri
    };

    try {
      const response = await ApiPostNew('/v1/questionarioAreaPergunta', body);
      dataCompleto.response = [
        ...(dataCompleto.response || []),
        response.data.mensagem.msg
      ];
    } catch (err) {
      console.log(err);
    }
  }

  let erros = [];
  for (let i = 0; i < dataCompleto.response.length; i++) {
    if (dataCompleto.response[i].slice(0, 3) !== '100') {
      erros.push(
        `Pergunta: ${dataCompleto.perguntasDescricao[i]} - Erro: ${dataCompleto.response[i]}`
      );
    }
  }

  if (erros.length === 0) {
    await Swal.fire({
      title: 'Sucesso!',
      html: `Todos os itens foram salvos com sucesso!`,
      icon: 'success',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok'
    });

    return true;
  } else {
    await Swal.fire({
      title: 'Erro ao salvar!',
      html: `Algumas perguntas tiveram erro:<br>${erros.join('<br>')}`,
      icon: 'error',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok'
    });

    return false;
  }
}

export async function questionarioAreaPerguntaUpdate(data) {
  try {
    const response = await ApiPut(
      '/v1/questionarioAreaPergunta',
      data,
      localStorage.getItem('sessionToken')
    );
    if (response.data.mensagem.msg.slice(0, 3) === '100') {
      alert('Registro atualizado com sucesso!');
      return true;
    }
    alert(response.data.mensagem.msg);
    return false;
  } catch (err) {
    console.log(err);
  }
}

export async function questionarioAreaPerguntaDelete(data) {
  await Swal.fire({
    title: 'Deletar registro?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Confirmar'
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        const response = await ApiDelete(
          '/v1/questionarioAreaPergunta',
          data,
          localStorage.getItem('sessionToken')
        );
        if (response.data.mensagem.msg.slice(0, 3) === '100') {
          alert('Registro deletado com sucesso!');
          return true;
        }
        alert(response.data.mensagem.msg);
        return false;
      } catch (err) {
        console.log(err);
      }
    }
  });
}

export async function questionarioAreaPerguntaSelect(dataFiltro) {
  try {
    const response = await ApiGet(
      '/v1/questionarioAreaPergunta',
      dataFiltro,
      localStorage.getItem('sessionToken')
    );
    return response?.data?.tabela;
  } catch (err) {
    console.log(err);
  }
}

export async function questionarioAreaPerguntaSelectUnique(dataFiltro) {
  try {
    const response = await ApiGet(
      '/v1/questionarioAreaPergunta/unicos',
      dataFiltro,
      localStorage.getItem('sessionToken')
    );
    return response?.data?.tabela;
  } catch (err) {
    console.log(err);
  }
}
