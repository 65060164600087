import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

function RelAuditoria(data, auditor) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const empresa = localStorage.getItem('sessionFantasia');

  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString();
  const formattedTime = currentDate.toLocaleTimeString();

  const formatDateApl = () => {
    const dateComplet = String(data[0][0].qpe_datapl).slice(0, 10);
    const dateSplit = dateComplet.split('-');
    return `${dateSplit[2]}/${dateSplit[1]}/${dateSplit[0]}`;
  };

  const reportTitle = [
    {
      fontSize: 13,
      bold: true,
      margin: [30, 25, 30, 0],
      alignment: 'left',
      columns: [{ text: empresa }]
    },
    {
      fontSize: 13,
      bold: true,
      margin: [30, 15, 30, 0],
      alignment: 'left',
      columns: [
        {
          text: 'AVALIAÇÃO DO PROGRAMA 5Ss',
          width: 200,
          alignment: 'left',
          bold: true
        },
        {
          stack: [
            {
              text: `Meta - ${parseFloat(
                localStorage.getItem('sessionMeta')
              ).toFixed(2)}`,
              background: 'green',
              color: 'white',
              margin: [15, 0, 0, 0],
              fontSize: 13,
              bold: true
            }
          ]
        },
        {
          stack: [
            {
              text: `Nota - ${parseFloat(data[1][0][0].nota_quest).toFixed(2)}`,
              background:
                parseFloat(data[1][0][0].nota_quest).toFixed(2) >=
                parseFloat(localStorage.getItem('sessionMeta'))
                  ? 'green'
                  : 'red',
              color: 'white',
              margin: [35, 0, 0, 0],
              fontSize: 13,
              bold: true
            }
          ]
        },
        {
          text: 'Data: ' + formattedDate + ' ' + formattedTime,
          alignment: 'right',
          fontSize: 8,
          bold: true
        }
      ]
    },
    {
      fontSize: 10,
      bold: true,
      margin: [30, 1, 30, 0],
      alignment: 'left',
      columns: [
        {
          text: `Ambiente: ${data[0][0].que_descri}`,
          alignment: 'left',
          bold: true
        },
        {
          text: `Auditor: ${auditor}`,
          alignment: 'center',
          bold: true
        },
        {
          text: 'Data Apl: ' + formatDateApl(formatDateApl),
          alignment: 'right',
          fontSize: 8,
          bold: true
        }
      ]
    }
  ];

  const styles = {
    tableHeader: {
      bold: true
    }
  };

  const dadosOrg = data[0]
    .filter((opt) => opt.sar_abrev === '1 - ORGA')
    .map((opt) => [
      {
        alignment: 'center',
        canvas: [
          {
            type: 'rect',
            x: 0,
            y: 0,
            w: 20,
            h: 10,
            color: opt.res_descri === 'ACERTOU' ? 'blue' : 'red'
          }
        ]
      },
      {
        text: opt.per_descri,
        alignment: 'left',
        bold: false,
        fontSize: 8,
        colSpan: 9
      },
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {}
    ]);

  const totalLinhasDadosOrg = dadosOrg.length;
  // const totalAcertosOrg = data.filter(
  //   (opt) => opt.res_descri === 'ACERTOU' && opt.sar_abrev === '1 - ORGA'
  // ).length;
  const totalAcertosOrg = data[1][0][0].qt_acertos;

  const totDadosOrg = [
    [
      {
        text: '1 S - ORGANIZAÇÃO',
        style: 'headerTitle',
        fillColor: '#dddddd',
        alignment: 'left',
        bold: true,
        fontSize: 10,
        colSpan: 10
      },
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {}
    ],
    ...dadosOrg,
    [
      {},
      {},
      {
        text: totalLinhasDadosOrg.toString(),
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true
      },
      {
        text: totalAcertosOrg.toString(),
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true
      },
      {
        text: '20%',
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true
      },
      {
        text: '0%',
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true,

        color:
          parseInt(data[1][0][0].resultado_subarea) === 0 ? 'black' : 'grey'
      },
      {
        text: '4%',
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true,

        color:
          parseInt(data[1][0][0].resultado_subarea) === 4 ? 'black' : 'grey'
      },
      {
        text: '10%',
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true,

        color:
          parseInt(data[1][0][0].resultado_subarea) === 10 ? 'black' : 'grey'
      },
      {
        text: '12%',
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true,

        color:
          parseInt(data[1][0][0].resultado_subarea) === 12 ? 'black' : 'grey'
      },
      {
        text: '20%',
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true,

        color:
          parseInt(data[1][0][0].resultado_subarea) === 20 ? 'black' : 'grey'
      }
    ]
  ];

  const dadosArr = data[0]
    .filter((opt) => opt.sar_abrev === '2 - ARRU')
    .map((opt) => [
      {
        alignment: 'center',
        canvas: [
          {
            type: 'rect',
            x: 0,
            y: 0,
            w: 20,
            h: 10,
            color: opt.res_descri === 'ACERTOU' ? 'blue' : 'red'
          }
        ]
      },
      {
        text: opt.per_descri,
        alignment: 'left',
        bold: false,
        fontSize: 8,
        colSpan: 9
      },
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {}
    ]);

  const totalLinhasDadosArr = dadosArr.length;
  // const totalAcertosArr = data.filter(
  //   (opt) => opt.res_descri === 'ACERTOU' && opt.sar_abrev === '2 - ARRU'
  // ).length;
  const totalAcertosArr = data[2][0][0].qt_acertos;

  const totDadosArr = [
    [
      {
        text: '2 S - ARRUMAÇÃO',
        style: 'headerTitle',
        fillColor: '#dddddd',
        alignment: 'left',
        bold: true,
        fontSize: 10,
        colSpan: 10
      },
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {}
    ],
    ...dadosArr,
    [
      {},
      {},
      {
        text: totalLinhasDadosArr.toString(),
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true
      },
      {
        text: totalAcertosArr.toString(),
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true
      },
      {
        text: '20%',
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true
      },
      {
        text: '0%',
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true,
        color:
          parseInt(data[2][0][0].resultado_subarea) === 0 ? 'black' : 'grey'
      },
      {
        text: '4%',
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true,
        color:
          parseInt(data[2][0][0].resultado_subarea) === 4 ? 'black' : 'grey'
      },
      {
        text: '10%',
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true,
        color:
          parseInt(data[2][0][0].resultado_subarea) === 10 ? 'black' : 'grey'
      },
      {
        text: '12%',
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true,
        color:
          parseInt(data[2][0][0].resultado_subarea) === 12 ? 'black' : 'grey'
      },
      {
        text: '20%',
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true,
        color:
          parseInt(data[2][0][0].resultado_subarea) === 20 ? 'black' : 'grey'
      }
    ]
  ];

  const dadosLim = data[0]
    .filter((opt) => opt.sar_abrev === '3 - LIMP')
    .map((opt) => [
      {
        alignment: 'center',
        canvas: [
          {
            type: 'rect',
            x: 0,
            y: 0,
            w: 20,
            h: 10,
            color: opt.res_descri === 'ACERTOU' ? 'blue' : 'red'
          }
        ]
      },
      {
        text: opt.per_descri,
        alignment: 'left',
        bold: false,
        fontSize: 8,
        colSpan: 9
      },
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {}
    ]);

  const totalLinhasDadosLim = dadosLim.length;
  // const totalAcertosLim = data.filter(
  //   (opt) => opt.res_descri === 'ACERTOU' && opt.sar_abrev === '3 - LIMP'
  // ).length;
  const totalAcertosLim = data[3][0][0].qt_acertos;

  const totDadosLim = [
    [
      {
        text: '3 S - LIMPEZA',
        style: 'headerTitle',
        fillColor: '#dddddd',
        alignment: 'left',
        bold: true,
        fontSize: 10,
        colSpan: 10
      },
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {}
    ],
    ...dadosLim,
    [
      {},
      {},
      {
        text: totalLinhasDadosLim.toString(),
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true
      },
      {
        text: totalAcertosLim.toString(),
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true
      },
      {
        text: '20%',
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true
      },
      {
        text: '0%',
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true,
        color:
          parseInt(data[3][0][0].resultado_subarea) === 0 ? 'black' : 'grey'
      },
      {
        text: '4%',
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true,
        color:
          parseInt(data[3][0][0].resultado_subarea) === 4 ? 'black' : 'grey'
      },
      {
        text: '10%',
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true,
        color:
          parseInt(data[3][0][0].resultado_subarea) === 10 ? 'black' : 'grey'
      },
      {
        text: '12%',
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true,
        color:
          parseInt(data[3][0][0].resultado_subarea) === 12 ? 'black' : 'grey'
      },
      {
        text: '20%',
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true,
        color:
          parseInt(data[3][0][0].resultado_subarea) === 20 ? 'black' : 'grey'
      }
    ]
  ];

  const dadosPad = data[0]
    .filter((opt) => opt.sar_abrev === '4 - PADR')
    .map((opt) => [
      {
        alignment: 'center',
        canvas: [
          {
            type: 'rect',
            x: 0,
            y: 0,
            w: 20,
            h: 10,
            color: opt.res_descri === 'ACERTOU' ? 'blue' : 'red'
          }
        ]
      },
      {
        text: opt.per_descri,
        alignment: 'left',
        bold: false,
        fontSize: 8,
        colSpan: 9
      },
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {}
    ]);

  const totalLinhasDadosPad = dadosPad.length;
  // const totalAcertosPad = data.filter(
  //   (opt) => opt.res_descri === 'ACERTOU' && opt.sar_abrev === '4 - PADR'
  // ).length;
  const totalAcertosPad = data[4][0][0].qt_acertos;

  const totDadosPad = [
    [
      {
        text: '4 S - PADRONIZAÇÃO',
        style: 'headerTitle',
        fillColor: '#dddddd',
        alignment: 'left',
        bold: true,
        fontSize: 10,
        colSpan: 10
      },
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {}
    ],
    ...dadosPad,
    [
      {},
      {},
      {
        text: totalLinhasDadosPad.toString(),
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true
      },
      {
        text: totalAcertosPad.toString(),
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true
      },
      {
        text: '20%',
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true
      },
      {
        text: '0%',
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true,
        color:
          parseInt(data[4][0][0].resultado_subarea) === 0 ? 'black' : 'grey'
      },
      {
        text: '4%',
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true,
        color:
          parseInt(data[4][0][0].resultado_subarea) === 4 ? 'black' : 'grey'
      },
      {
        text: '10%',
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true,
        color:
          parseInt(data[4][0][0].resultado_subarea) === 10 ? 'black' : 'grey'
      },
      {
        text: '12%',
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true,
        color:
          parseInt(data[4][0][0].resultado_subarea) === 12 ? 'black' : 'grey'
      },
      {
        text: '20%',
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true,
        color:
          parseInt(data[4][0][0].resultado_subarea) === 20 ? 'black' : 'grey'
      }
    ]
  ];

  const dadosDis = data[0]
    .filter((opt) => opt.sar_abrev === '5 - DISC')
    .map((opt) => [
      {
        alignment: 'center',
        canvas: [
          {
            type: 'rect',
            x: 0,
            y: 0,
            w: 20,
            h: 10,
            color: opt.res_descri === 'ACERTOU' ? 'blue' : 'red'
          }
        ]
      },
      {
        text: opt.per_descri,
        alignment: 'left',
        bold: false,
        fontSize: 8,
        colSpan: 9
      },
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {}
    ]);

  const totalLinhasDadosDis = dadosDis.length;
  // const totalAcertosDis = data.filter(
  //   (opt) => opt.res_descri === 'ACERTOU' && opt.sar_abrev === '5 - DISC'
  // ).length;
  const totalAcertosDis = data[5][0][0].qt_acertos;

  const totDadosDis = [
    [
      {
        text: '5 S - DISCIPLINA',
        style: 'headerTitle',
        fillColor: '#dddddd',
        alignment: 'left',
        bold: true,
        fontSize: 10,
        colSpan: 10
      },
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {}
    ],
    ...dadosDis,
    [
      {},
      {},
      {
        text: totalLinhasDadosDis.toString(),
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true
      },
      {
        text: totalAcertosDis.toString(),
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true
      },
      {
        text: '20%',
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true
      },
      {
        text: '0%',
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true,
        color:
          parseInt(data[5][0][0].resultado_subarea) === 0 ? 'black' : 'grey'
      },
      {
        text: '4%',
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true,
        color:
          parseInt(data[5][0][0].resultado_subarea) === 4 ? 'black' : 'grey'
      },
      {
        text: '10%',
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true,
        color:
          parseInt(data[5][0][0].resultado_subarea) === 10 ? 'black' : 'grey'
      },
      {
        text: '12%',
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true,
        color:
          parseInt(data[5][0][0].resultado_subarea) === 12 ? 'black' : 'grey'
      },
      {
        text: '20%',
        style: 'headerTitle',
        alignment: 'center',
        fontSize: 8,
        bold: true,
        color:
          parseInt(data[5][0][0].resultado_subarea) === 20 ? 'black' : 'grey'
      }
    ]
  ];

  const details = [
    {
      style: 'header',
      table: {
        widths: [
          'auto',
          '*',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto',
          'auto'
        ],
        body: [
          [
            {
              text: 'SENSOS',
              style: 'headerTitle',
              fillColor: '#dddddd',
              alignment: 'center',
              bold: true,
              fontSize: 12
            },
            {
              text: 'CRITÉRIOS DE AVALIAÇÃO',
              style: 'headerTitle',
              fillColor: '#dddddd',
              alignment: 'center',
              bold: true,
              fontSize: 12
            },
            {
              text: 'QTD ITENS',
              style: 'headerTitle',
              fillColor: '#dddddd',
              alignment: 'center',
              fontSize: 8,
              bold: true
            },
            {
              text: 'QTD OK',
              style: 'headerTitle',
              fillColor: '#dddddd',
              alignment: 'center',
              fontSize: 8,
              bold: true
            },
            {
              text: 'PESO',
              style: 'headerTitle',
              fillColor: '#dddddd',
              alignment: 'center',
              fontSize: 8,
              bold: true
            },
            {
              text: 'PONTUAÇÃO',
              style: 'headerTitle',
              fillColor: '#dddddd',
              colSpan: 5,
              alignment: 'center',
              fontSize: 8,
              bold: true
            },
            {},
            {},
            {},
            {}
          ],
          [
            { text: '', fillColor: '#dddddd' },
            { text: '', fillColor: '#dddddd' },
            { text: '', fillColor: '#dddddd' },
            { text: '', fillColor: '#dddddd' },
            { text: '', fillColor: '#dddddd' },
            {
              text: 'NADA',
              style: 'headerTitle',
              fillColor: '#dddddd',
              alignment: 'center',
              fontSize: 8,
              bold: true
            },
            {
              text: 'MINORIA',
              style: 'headerTitle',
              fillColor: '#dddddd',
              alignment: 'center',
              fontSize: 8,
              bold: true
            },
            {
              text: 'MEIO',
              style: 'headerTitle',
              fillColor: '#dddddd',
              alignment: 'center',
              fontSize: 8,
              bold: true
            },
            {
              text: 'MAIORIA',
              style: 'headerTitle',
              fillColor: '#dddddd',
              alignment: 'center',
              fontSize: 8,
              bold: true
            },
            {
              text: 'TOTAL',
              style: 'headerTitle',
              fillColor: '#dddddd',
              alignment: 'center',
              fontSize: 8,
              bold: true
            }
          ],
          ...totDadosOrg,
          ...totDadosArr,
          ...totDadosLim,
          ...totDadosPad,
          ...totDadosDis
        ]
      },
      layout: {
        hLineWidth: function () {
          return 0;
        },
        vLineWidth: function () {
          return 0;
        }
      }
    }
  ];

  const detailsRodape = [
    {
      text: ' ',
      pageBreak: 'after'
    },
    {
      style: 'header',
      table: {
        widths: ['auto', 'auto', '*'],
        body: [
          [
            {
              text: 'ITENS DO\nSETOR',
              fillColor: '#dddddd',
              colSpan: 2,
              margin: [0, 0],
              fontSize: 10,
              alignment: 'center',
              bold: true
            },
            {},
            { text: '', fillColor: '#ffffff' }
          ]
        ]
      }
    }
  ];

  const detailsRodape2 = [
    {
      style: 'header',
      table: {
        widths: ['*', 'auto', 60, 60, 60],
        body: [
          [
            {
              text: 'OPORTUNIDADES DE MELHORIA',
              fillColor: '#dddddd',
              colSpan: 1,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'center',
              bold: true
            },
            {
              text: 'RESULTADOS POR SENSO',
              fillColor: '#dddddd',
              colSpan: 2,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'center',
              bold: true
            },
            {},
            {
              text: '%',
              fillColor: '#dddddd',
              colSpan: 1,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'center',
              bold: true
            },
            {
              text: 'Nota',
              fillColor: '#dddddd',
              colSpan: 1,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'center',
              bold: true
            }
          ],
          [
            {
              text: '1º',
              fillColor: '#fff',
              colSpan: 1,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'left',
              bold: true
            },
            {
              text: '1º S - ORGANIZAÇÃO',
              fillColor: '#dddddd',
              colSpan: 2,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'center',
              bold: true
            },
            {},
            {
              text: `${String(parseInt(data[1][0][0].resultado_subarea))}%`,
              fillColor: '#fff',
              colSpan: 1,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'center',
              bold: true
            },
            {
              text: `${(
                parseFloat(data[1][0][0].resultado_subarea) / 10
              ).toFixed(2)}`,
              fillColor: '#fff',
              colSpan: 1,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'center',
              bold: true
            }
          ],
          [
            {
              text: '2º',
              fillColor: '#fff',
              colSpan: 1,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'left',
              bold: true
            },
            {
              text: '2º S - ARRUMAÇÃO',
              fillColor: '#dddddd',
              colSpan: 2,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'center',
              bold: true
            },
            {},
            {
              text: `${String(parseInt(data[2][0][0].resultado_subarea))}%`,
              fillColor: '#fff',
              colSpan: 1,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'center',
              bold: true
            },
            {
              text: `${(
                parseFloat(data[2][0][0].resultado_subarea) / 10
              ).toFixed(2)}`,
              fillColor: '#fff',
              colSpan: 1,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'center',
              bold: true
            }
          ],
          [
            {
              text: '3º',
              fillColor: '#fff',
              colSpan: 1,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'left',
              bold: true
            },
            {
              text: '3º S -  LIMPEZA',
              fillColor: '#dddddd',
              colSpan: 2,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'center',
              bold: true
            },
            {},
            {
              text: `${String(parseInt(data[3][0][0].resultado_subarea))}%`,
              fillColor: '#fff',
              colSpan: 1,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'center',
              bold: true
            },
            {
              text: `${(
                parseFloat(data[3][0][0].resultado_subarea) / 10
              ).toFixed(2)}`,
              fillColor: '#fff',
              colSpan: 1,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'center',
              bold: true
            }
          ],
          [
            {
              text: '',
              fillColor: '#fff',
              colSpan: 1,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'left',
              bold: true
            },
            {
              text: '4º S -  PADRONIZAÇÃO',
              fillColor: '#dddddd',
              colSpan: 2,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'center',
              bold: true
            },
            {},
            {
              text: `${String(parseInt(data[4][0][0].resultado_subarea))}%`,
              fillColor: '#fff',
              colSpan: 1,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'center',
              bold: true
            },
            {
              text: `${(
                parseFloat(data[4][0][0].resultado_subarea) / 10
              ).toFixed(2)}`,
              fillColor: '#fff',
              colSpan: 1,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'center',
              bold: true
            }
          ],
          [
            {
              text: 'ASS. RESPONSÁVEL/ LIDER ',
              fillColor: '#dddddd',
              colSpan: 1,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'center',
              bold: true
            },
            {
              text: '5º S -  DISCIPLINA',
              fillColor: '#dddddd',
              colSpan: 2,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'center',
              bold: true
            },
            {},
            {
              text: `${String(parseInt(data[5][0][0].resultado_subarea))}%`,
              fillColor: '#fff',
              colSpan: 1,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'center',
              bold: true
            },
            {
              text: `${(
                parseFloat(data[5][0][0].resultado_subarea) / 10
              ).toFixed(2)}`,
              fillColor: '#fff',
              colSpan: 1,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'center',
              bold: true
            }
          ],
          [
            {
              text: '',
              fillColor: '#fff',
              colSpan: 3,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'center',
              bold: true,
              border: [false, false, false, false]
            },
            {},
            {},
            {
              text: '%',
              fillColor: '#dddddd',
              colSpan: 1,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'center',
              bold: true
            },
            {
              text: 'NOTA',
              fillColor: '#dddddd',
              colSpan: 1,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'center',
              bold: true
            }
          ],
          [
            {
              text: '',
              fillColor: '#fff',
              colSpan: 1,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'center',
              bold: true
            },
            {
              text: 'META',
              fillColor: '#dddddd',
              colSpan: 1,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'center',
              bold: true
            },
            {
              text: 'RESULTADO SOMATÓRIO',
              fillColor: '#dddddd',
              colSpan: 1,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'center',
              rowSpan: 2,
              bold: true
            },
            {
              text: `${String(
                parseInt(data[1][0][0].resultado_subarea) +
                  parseInt(data[2][0][0].resultado_subarea) +
                  parseInt(data[3][0][0].resultado_subarea) +
                  parseInt(data[4][0][0].resultado_subarea) +
                  parseInt(data[5][0][0].resultado_subarea)
              )}%`,
              fillColor: '#fff',
              colSpan: 1,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'center',
              rowSpan: 2,
              bold: true
            },
            {
              text: `${(
                parseFloat(data[1][0][0].resultado_subarea / 10) +
                parseFloat(data[2][0][0].resultado_subarea / 10) +
                parseFloat(data[3][0][0].resultado_subarea / 10) +
                parseFloat(data[4][0][0].resultado_subarea / 10) +
                parseFloat(data[5][0][0].resultado_subarea / 10)
              ).toFixed(2)}`,
              fillColor: '#fff',
              colSpan: 1,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'center',
              rowSpan: 2,
              bold: true
            }
          ],
          [
            {
              text: 'ASS. AUDITOR',
              fillColor: '#dddddd',
              colSpan: 1,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'center',
              bold: true
            },
            {
              text: parseFloat(localStorage.getItem('sessionMeta')).toFixed(2),
              fillColor: 'green',
              colSpan: 1,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'center',
              bold: true
            },
            {
              text: '',
              fillColor: '#dddddd',
              colSpan: 1,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'center',
              bold: true
            },
            {
              text: '',
              fillColor: '#fff',
              colSpan: 1,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'center',
              bold: true
            },
            {
              text: '',
              fillColor: '#fff',
              colSpan: 1,
              margin: [0, 0],
              fontSize: 8,
              alignment: 'center',
              bold: true
            }
          ]
        ]
      }
    }
  ];

  const signature = [
    {
      style: 'header',
      margin: [0, 25],
      table: {
        widths: ['*', '*'],
        body: [
          [
            {
              canvas: [
                {
                  type: 'line',
                  x1: 0,
                  y1: 5,
                  x2: 275,
                  y2: 5,
                  lineWidth: 1,
                  lineColor: 'black'
                }
              ],
              margin: [0, 5],
              border: [false, false, false, false]
            },
            {
              canvas: [
                {
                  type: 'line',
                  x1: 0,
                  y1: 5,
                  x2: 275,
                  y2: 5,
                  lineWidth: 1,
                  lineColor: 'black'
                }
              ],
              margin: [0, 5],
              border: [false, false, false, false]
            }
          ],
          [
            {
              text: 'ASS. RESPONSÁVEL/LÍDER',
              style: 'headerTitle',
              alignment: 'center',
              fontSize: 10
            },
            {
              text: 'ASS. AUDITOR',
              style: 'headerTitle',
              alignment: 'center',
              fontSize: 10
            }
          ]
        ]
      },
      layout: {
        hLineWidth: function () {
          return 0;
        },
        vLineWidth: function () {
          return 0;
        }
      }
    }
  ];

  function Rodape(currentPage, pageCount) {
    return [
      {
        text: currentPage + ' / ' + pageCount,
        alignment: 'right',
        fontSize: 9,
        margin: [0, 10, 20, 0]
      }
    ];
  }

  const docDefinitios = {
    pageSize: 'A4',
    pageMargins: [15, 90, 15, 40],

    header: [reportTitle],
    content: [details, detailsRodape, detailsRodape2],
    footer: Rodape,
    styles: styles
  };

  pdfMake.createPdf(docDefinitios).download();
}

export default RelAuditoria;
