import { useState, useEffect } from 'react';

import { ApiGet, ApiGetConsulta } from '../../../services/apiService';
import { Grid } from './Grid';

export const CtrNavegacao = (props) => {
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const itemsPerPage = 10;
  const offset = currentPage * itemsPerPage;

  function getDisplayedPageNumbers(currentPage, pageCount) {
    const delta = 2;
    const minPage = Math.max(currentPage - delta, 0);
    const maxPage = Math.min(currentPage + delta, pageCount - 1);
    const pageNumbers = [];
    for (let i = minPage; i <= maxPage; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  }

  const displayedPageNumbers = getDisplayedPageNumbers(currentPage, pageCount);

  const handleRowClick = (index) => {
    setSelectedRow(index);
  };

  const handlePageClick = (pageNumber) => {
    if (pageNumber < 0 || pageNumber >= pageCount) {
      return;
    }
    setCurrentPage(pageNumber);
    setSelectedRow(null);
  };

  const handleFirstPage = () => {
    setCurrentPage(0);
    setSelectedRow(null);
  };

  const handleLastPage = () => {
    setCurrentPage(pageCount - 1);
    setSelectedRow(null);
  };

  const handleSearch = async () => {
    try {
      const response = await ApiGetConsulta(
        '/auditoria',
        localStorage.getItem('sessionToken')
      );
      const sortedData = response.data.auditoria.sort((a, b) => {
        if (a.que_descri < b.que_descri) return -1;
        if (a.que_descri > b.que_descri) return 1;
        return 0;
      });

      props.setData(sortedData);
      props.setContRegistros(response.data.auditoria.length);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (localStorage.getItem('sessionToken')) {
      handleSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem('sessionToken')]);

  useEffect(() => {
    if (props.data != undefined) {
      setFilteredData(props.data.slice(offset, offset + itemsPerPage));
      setPageCount(Math.ceil(props.data.length / itemsPerPage));
      setCurrentPage(0);
      setSelectedRow(null);
    }
    // eslint-disable-next-line
  }, [props.data]);

  useEffect(() => {
    if (props.data !== undefined) {
      setFilteredData(props.data.slice(offset, offset + itemsPerPage));
    }
    // eslint-disable-next-line
  }, [currentPage]);

  return (
    <div>
      <div>
        <Grid
          filteredData={filteredData}
          selectedRow={selectedRow}
          currentPage={currentPage}
          pageCount={pageCount}
          handlePageClick={handlePageClick}
          handleFirstPage={handleFirstPage}
          handleLastPage={handleLastPage}
          handleRowClick={handleRowClick}
          handleEdit={props.handleEdit}
          displayedPageNumbers={displayedPageNumbers}
          contRegistros={props.contRegistros}
          handleSort={props.handleSort}
          tamTexto={props.tamTexto}
          tamSubTexto={props.tamSubTexto}
          isLoadingEdit={props.isLoadingEdit}
        />
      </div>
    </div>
  );
};
