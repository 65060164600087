import { useEffect, useState } from 'react';

import {
  getDisplayedPageNumbers,
  perguntaPost,
  perguntaSelect,
  perguntaUpdate
} from './functions';
import { Cadastro } from './perguntas/Cadastro';
import { Grid } from './perguntas/Grid';

export const Perguntas = (props) => {
  const [activeTab, setActiveTab] = useState(1);
  const [dataGrid, setDataGrid] = useState([]);
  const [insertOrUpdate, setInsertOrUpdate] = useState(null);
  const [data, setData] = useState({
    usuario: localStorage.getItem('sessionUsuario')
  });

  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const itemsPerPage = 10;
  const offset = currentPage * itemsPerPage;

  const displayedPageNumbers = getDisplayedPageNumbers(currentPage, pageCount);

  const handleSearch = async (dataFiltro) => {
    const filtro = {
      ...dataFiltro,
      descricao: dataFiltro?.descricao
        ? { like: `%${dataFiltro.descricao.toUpperCase()}%` }
        : '',
      abreviacao: dataFiltro?.abreviacao
        ? { like: `%${dataFiltro.abreviacao.toUpperCase()}%` }
        : '',
      palavraChave: dataFiltro?.palavraChave
        ? { like: `%${dataFiltro.palavraChave.toUpperCase()}%` }
        : ''
    };

    setDataGrid(await perguntaSelect(filtro));
  };

  const handleSort = (order, direction) => {
    let multiplier = direction === 'asc' ? 1 : -1;
    switch (order) {
      case 'id':
        setDataGrid(
          [...dataGrid].sort((a, b) => multiplier * a.id.localeCompare(b.id))
        );
        break;
      case 'descricao':
        setDataGrid(
          [...dataGrid].sort(
            (a, b) => multiplier * a.descricao.localeCompare(b.descricao)
          )
        );
        break;
      case 'abreviacao':
        setDataGrid(
          [...dataGrid].sort(
            (a, b) => multiplier * a.abreviacao.localeCompare(b.abreviacao)
          )
        );
        break;
      case 'palavraChave':
        setDataGrid(
          [...dataGrid].sort(
            (a, b) => multiplier * a.palavraChave.localeCompare(b.palavraChave)
          )
        );
        break;
      default:
        setDataGrid([...dataGrid]);
        break;
    }
  };

  useEffect(() => {
    if (props.activeTab === 4) {
      handleSearch(props.dataFiltro);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeTab, props.filter]);

  useEffect(() => {
    if (dataGrid != undefined) {
      setFilteredData(dataGrid.slice(offset, offset + itemsPerPage));
      setPageCount(Math.ceil(dataGrid.length / itemsPerPage));
      setCurrentPage(0);
      setSelectedRow(null);
    }
    // eslint-disable-next-line
  }, [dataGrid]);

  useEffect(() => {
    if (dataGrid !== undefined) {
      setFilteredData(dataGrid.slice(offset, offset + itemsPerPage));
    }
    // eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    if (props.tabInsert === 4) {
      const data = new Date();
      const ano = data.getFullYear();
      const mes = String(data.getMonth() + 1).padStart(2, '0');
      const dia = String(data.getDate()).padStart(2, '0');

      const hora = data.getHours();
      const minuto = String(data.getMinutes()).padStart(2, '0');
      const segundo = String(data.getSeconds()).padStart(2, '0');

      setData({
        ...data,
        empresa: localStorage.getItem('sessionEmpresa'),
        loja: localStorage.getItem('sessionLoja'),
        usuario: localStorage.getItem('sessionUsuario'),
        dataInclusao: `${ano}-${mes}-${dia}`,
        dataAtualizacao: `${ano}-${mes}-${dia}`,
        horaAtualizacao: `${hora}:${minuto}:${segundo}`,
        horaInclusao: `${hora}:${minuto}:${segundo}`
      });

      props.setTitle(`${props.title} - Inserir`);
      setInsertOrUpdate('insert');
      props.setNvAcesso('insert');
      setActiveTab(2);
      props.setTabReturn(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tabInsert]);

  useEffect(() => {
    if (props.tabReturn === 0) {
      setActiveTab(1);
      setData({ usuario: localStorage.getItem('sessionUsuario') });
    }
  }, [props.tabReturn]);

  useEffect(() => {
    if (props.tabUpdate === 4) {
      const fetch = async () => {
        const body = {
          ...data,
          descricao: data?.descricao?.toUpperCase(),
          abreviacao: data?.abreviacao?.toUpperCase(),
          palavraChave: data?.palavraChave?.toUpperCase()
        };

        if (insertOrUpdate === 'insert') await perguntaPost(body);
        else await perguntaUpdate(body);

        const texto = props.title.split(' - ');
        props.setTitle(texto[0]);

        setData({
          usuario: localStorage.getItem('sessionUsuario')
        });

        await handleSearch();

        setActiveTab(1);
        props.setTabReturn(0);
        props.setTabUpdate(0);
        props.setTabInsert(0);
      };
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tabUpdate]);

  return (
    <div>
      <div>
        <div className="nav nav-tabs">
          <p
            className={`nav-link ${
              activeTab === 1 ? 'text-primary active' : 'text-dark'
            }`}
            onClick={() => setActiveTab(1)}
            style={{ display: 'none' }}
          >
            {/* Tabela */}
          </p>
          <p
            className={`nav-link ${
              activeTab === 2 ? 'text-primary active' : 'text-dark'
            }`}
            onClick={() => setActiveTab(2)}
            style={{ display: 'none' }}
          >
            {/* Insert */}
          </p>
        </div>

        <div className="tab-content">
          <div
            className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}
            id="tab1"
          >
            <Grid
              filteredData={filteredData}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageCount={pageCount}
              displayedPageNumbers={displayedPageNumbers}
              setActiveTab={setActiveTab}
              data={data}
              setData={setData}
              dataGrid={dataGrid}
              setInsertOrUpdate={setInsertOrUpdate}
              handleSearch={handleSearch}
              tamTexto={props.tamTexto}
              tamSubTexto={props.tamSubTexto}
              setTabReturn={props.setTabReturn}
              setNvAcesso={props.setNvAcesso}
              handleSort={handleSort}
              setTitle={props.setTitle}
              title={props.title}
            />
          </div>
          <div
            className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}
            id="tab2"
          >
            <Cadastro
              data={data}
              setData={setData}
              insertOrUpdate={insertOrUpdate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
