import React from 'react';

export const FiltrosDate = (props) => {
  let title1 = props.title1;
  let title2 = props.title2;

  if (props.title1 === undefined && props.title2 === undefined) {
    title1 = 'Data Inicial';
    title2 = 'Data Final';
  }

  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ];
  const years = Array.from(
    { length: 3 },
    (_, i) => new Date().getFullYear() - i
  );

  const handleStartMonthChange = (event) => {
    props.setStartMonth(event.target.value);
    props.setDataInicial(props.startYear + event.target.value.padStart(2, '0'));
  };

  const handleStartYearChange = (event) => {
    props.setStartYear(event.target.value);
    props.setDataInicial(
      event.target.value + props.startMonth.padStart(2, '0')
    );
  };

  const handleEndMonthChange = (event) => {
    props.setEndMonth(event.target.value);
    props.setDataFinal(props.endYear + event.target.value.padStart(2, '0'));
  };

  const handleEndYearChange = (event) => {
    props.setEndYear(event.target.value);
    props.setDataFinal(event.target.value + props.endMonth.padStart(2, '0'));
  };

  return (
    <div className="w-100 d-flex flex-column flex-md-row gap-2">
      <div
        className="d-flex flex-column w-100 p-1 rounded"
        style={{ backgroundColor: '#fff' }}
      >
        <label className="form-label">{title1}</label>
        <div className="d-flex">
          <select
            className="form-control"
            // value={props.startMonth}
            value={props.startMonth ? props.startMonth.replace(/^0+/, '') : ''}
            onChange={handleStartMonthChange}
          >
            {months.map((month, index) => (
              <option key={index} value={index + 1}>
                {month}
              </option>
            ))}
          </select>
          <select
            className="form-control"
            value={props.startYear.slice(0, 4)}
            onChange={handleStartYearChange}
          >
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>

      {props.isDataFinal === true && (
        <div
          className="d-flex flex-column w-100 p-1 rounded"
          style={{ backgroundColor: '#fff' }}
        >
          <label className="form-label">{title2}</label>
          <div className="d-flex">
            <select
              className="form-control"
              // value={props.endMonth}
              value={props.endMonth ? props.endMonth.replace(/^0+/, '') : ''}
              onChange={handleEndMonthChange}
            >
              {months.map((month, index) => (
                <option key={index} value={index + 1}>
                  {month}
                </option>
              ))}
            </select>
            <select
              className="form-control"
              value={props.endYear.slice(0, 4)}
              onChange={handleEndYearChange}
            >
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
    </div>
  );
};
