import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

import { ApiGet, ApiPostNew } from '../services/apiService';
import { Modal1 } from './Modal1';
import { Modal2 } from './Modal2';
import { Modal3 } from './Modal3';
import { Modal4 } from './Modal4';
import { Modal5 } from './Modal5';
import { Modal6 } from './Modal6';

const Card = (props) => {
  const [options, setOptions] = useState([]);
  const [series, setSeries] = useState([]);

  const handleSearch = async (datini, datend) => {
    const datiniSplit = datini.split('/');
    const datendSplit = datend.split('/');
    try {
      const response = await ApiPostNew(
        '/auditoria/graficomediaempresa/',
        {
          dateIni: `${datiniSplit[2]}${datiniSplit[1]}`,
          dateFim: `${datendSplit[2]}${datendSplit[1]}`
        },
        localStorage.getItem('sessionToken')
      );
      let values = [];
      response.data?.auditoria?.map((item) => {
        values.push({
          data: item?.media_empresa_dia,
          categories: item?.anomes
        });
      });

      const categories = values.map((item) => item.categories);
      const data = values.map((item) => item.data);

      setOptions({
        xaxis: {
          categories,
          labels: {
            formatter: function (value) {
              const date = String(value);
              const Ano = date.slice(0, 4);
              const Mes = date.slice(4);
              return `${Mes}/${Ano}`;
            },
            show: true
          }
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              const vl = parseFloat(value);
              return vl.toLocaleString('pt-BR', {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              });
            }
          }
        },
        chart: {
          zoom: {
            enabled: false
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            dataLabels: {
              position: 'top'
            },
            colors: {
              ranges: [
                {
                  from: 0,
                  to:
                    parseFloat(localStorage.getItem('sessionMeta')).toFixed(2) -
                    0.01,
                  color: '#DE1616'
                },
                {
                  from: parseFloat(localStorage.getItem('sessionMeta')).toFixed(
                    2
                  ),
                  to: 10,
                  color: '#0D6EFD'
                }
              ]
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (value) {
            const vl = parseFloat(value);
            return vl.toLocaleString('pt-BR', {
              style: 'decimal',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            });
          },
          offsetY: -20,
          style: {
            colors: ['#000']
          }
        }
      });

      setSeries([
        {
          name: 'Media da Empresa',
          data: data,
          type: 'bar'
        }
      ]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (localStorage.getItem('sessionToken'))
      handleSearch(props.datPeriodDashboardIni, props.datPeriodDashboardEnd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datPeriodDashboardIni, props.datPeriodDashboardEnd]);

  return (
    <>
      <div
        className="card"
        tabIndex="-1"
        data-bs-toggle="modal"
        data-bs-target="#ModalLista"
        style={{
          backgroundColor: props.bgDashboard,
          color: props.fontDashboard,
          height: '175px'
        }}
      >
        <div className="content">
          <div className="row">
            <div className="col d-flex flex-column align-items-center justify-content-center">
              <h6 style={{ fontSize: props.tamTexto }}>{props.title}</h6>
              <div className="d-flex justify-content-between align-items-center w-100 px-2">
                <i className={`${props.icon} icon`}></i>
                <div className="w-100">
                  {series.length >= 1 && (
                    <Chart
                      options={options}
                      series={series}
                      type="bar"
                      width="100%"
                      height="150px"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalLista comboboxSeqque={props.comboboxSeqque} />
    </>
  );
};

export const ModalLista = (props) => {
  const listaMenu = [
    { id: '1', name: 'Barra - Nota do Ambiente/Media da Empresa' },
    { id: '5', name: 'Linha - Nota do Ambiente/Media da Empresa' },
    { id: '2', name: 'Evolução da Nota de Auditoria por ambiente/periodo' },
    {
      id: '3',
      name: 'Media de nota de Ambientes por Unidade Adm/Equipe no periodo'
    },
    {
      id: '4',
      name: 'Media da Empresa dos S por mês das Notas das aplicação no periodo'
    },
    {
      id: '6',
      name: 'Nota dos Ambientes Mensal'
    }
    // {
    //   id: '6',
    //   name: 'Resultado Ambientes Mesal'
    // }
  ];

  return (
    <>
      <div
        className="modal fade"
        id="ModalLista"
        tabIndex="-1"
        aria-labelledby="ModalListaLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <ul className="list-group">
              {listaMenu.map((item) => (
                <li key={item.id} className="list-group-item">
                  <button
                    className="btn w-100"
                    style={{
                      fontSize: props.tamTexto,
                      backgroundColor: '#e9ecef',
                      transition: 'background-color 0.3s'
                    }}
                    onMouseOver={(event) => {
                      event.target.style.backgroundColor = '#6c757d';
                    }}
                    onMouseOut={(event) => {
                      event.target.style.backgroundColor = '#e9ecef';
                    }}
                    data-bs-toggle="modal"
                    data-bs-target={
                      item.id === '1'
                        ? '#ModalGrafico1'
                        : item.id === '2'
                        ? '#ModalGrafico2'
                        : item.id === '3'
                        ? '#ModalGrafico3'
                        : item.id === '4'
                        ? '#ModalGrafico4'
                        : item.id === '5'
                        ? '#ModalGrafico5'
                        : '#ModalGrafico6'
                    }
                  >
                    {item.name}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <Modal1 OpcoesSequencial={props.comboboxSeqque} />
      <Modal2 OpcoesSequencial={props.comboboxSeqque} />
      <Modal3 OpcoesSequencial={props.comboboxSeqque} />
      <Modal4 OpcoesSequencial={props.comboboxSeqque} />
      <Modal5 OpcoesSequencial={props.comboboxSeqque} />
      <Modal6 OpcoesSequencial={props.comboboxSeqque} />
    </>
  );
};

function Dashboard(props) {
  const [comboboxSeqque, setComboboxSeqque] = useState([]);
  const handleCombobox = async () => {
    try {
      const response = await ApiGet(
        '/combobox/details',
        { combobox: { table: 'questionario' } },
        props.token
      );
      setComboboxSeqque(response.data.combobox);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (props.token) {
      handleCombobox();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.token]);

  return (
    <>
      <div style={{ width: '95vw', marginLeft: '2.5vw' }}>
        <div className="row mt-3">
          <div className="col-sm-12 col-md-12 col-lg-4 mb-1">
            <div>
              {localStorage.getItem('sessionToken') ? (
                <div>
                  <Card
                    modalId="#ModalGrafico1"
                    modal="modal"
                    title="Media da Empresa no Ano"
                    icon="fa fa-solid fa-chart-column"
                    bgDashboard="rgb(191, 199, 189)"
                    fontDashboard={props.fontDashboard1}
                    tamTexto={props.tamTexto}
                    tamSubTexto={props.tamSubTexto}
                    comboboxSeqque={comboboxSeqque}
                    datPeriodDashboardIni={props.datPeriodDashboardIni}
                    datPeriodDashboardEnd={props.datPeriodDashboardEnd}
                  />
                </div>
              ) : (
                <div
                  className="card"
                  tabIndex="-1"
                  data-bs-toggle="modal"
                  data-bs-target="#msgError"
                  style={{
                    backgroundColor: 'rgb(191, 199, 189)',
                    color: props.fontDashboard1
                  }}
                >
                  <div className="content">
                    <div className="row">
                      <div className="col d-flex flex-column align-items-center justify-content-center">
                        <h6 style={{ fontSize: props.tamTexto }}>
                          Grafico Nota Questionario por ambiente/periodo
                        </h6>
                        <i className={`fa fa-solid fa-chart-column icon`}></i>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="msgError"
        tabIndex="-1"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div
                className="alert alert-danger alert-dismissible"
                role="alert"
              >
                <div style={{ fontSize: props.tamSubTitulo }}>Não Logado</div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
