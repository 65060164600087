import { useEffect, useState } from 'react';

import { ApiGetConsulta } from '../../services/apiService';
import { Ambiente } from './Ambiente';
import { SearchAmbiente } from './ambiente/SearchAmbiente';
import { Area } from './Area';
import {
  ambienteSelect,
  areaSelect,
  perguntaSelect,
  subareaSelect
} from './functions';
import { Perguntas } from './Perguntas';
import { SearchPerguntas } from './perguntas/SearchPerguntas';
import { QuestionarioArea } from './QuestionarioArea';
import { QuestionarioAreaPergunta } from './QuestionarioAreaPergunta';
import { SearchAdicionarPergunta } from './questionarioAreaPergunta/SearchAdicionarPergunta';
import { SearchQuestionarioAreaPergunta } from './questionarioAreaPergunta/SearchQuestionarioAreaPergunta';
import { Respostas } from './Respostas';
import { SearchRespostas } from './respostas/SearchRespostas';
import { Subarea } from './Subarea';
import { SearchSubarea } from './subarea/SearchSubarea';
import { SearchSubareaPerguntas } from './subarea/SearchSubareaPerguntas';

export const Manutencao = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const [tabInsert, setTabInsert] = useState(0);
  const [tabUpdate, setTabUpdate] = useState(0);
  const [tabReturn, setTabReturn] = useState(0);
  const [nvAcesso, setNvAcesso] = useState('');
  const [title, setTitle] = useState('');
  const [dataFiltro, setDataFiltro] = useState({});
  const [options, setOptions] = useState([]);
  const [filter, setFilter] = useState(0);
  const [copy, setCopy] = useState(0);
  const [isAdicionarPergunta, setIsAdicionarPergunta] = useState(false);

  const handleSearch = async () => {
    const responseArea = await areaSelect();
    const responseSubArea = await subareaSelect({});
    const responsePergunta = await perguntaSelect({});
    const responseQuestionario = await ambienteSelect({});

    const response1 = await ApiGetConsulta(
      '/tabela/empresa',
      localStorage.getItem('sessionToken')
    );
    const resEmpresa = response1.data.tabela;

    const response2 = await ApiGetConsulta(
      '/tabela/loja',
      localStorage.getItem('sessionToken')
    );
    const resLoja = response2.data.tabela;

    const response3 = await ApiGetConsulta(
      '/tabela/unidadeAdm',
      localStorage.getItem('sessionToken')
    );
    const resUndAdm = response3.data.tabela;

    setOptions([
      responseArea, //0
      resEmpresa, //1
      resLoja, //2
      resUndAdm, //3
      responseSubArea, //4
      responsePergunta, //5
      responseQuestionario //6
    ]);
  };

  useEffect(() => {
    setDataFiltro({});
  }, [activeTab]);

  useEffect(() => {
    handleSearch();
  }, []);

  return (
    <div
      className="modal fade modal-xl"
      id="Menu-Manutencao"
      tabIndex="-1"
      aria-labelledby="lblManutencao"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-scrollable modalDialog">
        <div className="modal-content" style={{ height: '100vh' }}>
          <div className="modal-header" style={{ height: '3rem' }}>
            <h1
              className="modal-title"
              id="lblManutencao"
              style={{ fontSize: props.tamTitulo }}
            >
              {activeTab === 3 || activeTab === 6
                ? title
                : `Manutenção ${title && title}`}
            </h1>
            {activeTab === 0 && (
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            )}
          </div>

          <div className="modal-body">
            {activeTab > 0 && (
              <div
                className="offcanvas my-offcanvas"
                tabIndex="-1"
                id="offcanvasTransferencia"
                aria-labelledby="offcanvasExampleLabel"
              >
                <div className="offcanvas-header d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body">
                  {activeTab === 2 && tabReturn === 0 && (
                    <SearchSubarea
                      dataFiltro={dataFiltro}
                      setDataFiltro={setDataFiltro}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      options={options}
                      filter={filter}
                      setFilter={setFilter}
                    />
                  )}

                  {activeTab === 3 && (
                    <SearchAmbiente
                      dataFiltro={dataFiltro}
                      setDataFiltro={setDataFiltro}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      options={options}
                      filter={filter}
                      setFilter={setFilter}
                    />
                  )}

                  {activeTab === 4 && (
                    <SearchPerguntas
                      dataFiltro={dataFiltro}
                      setDataFiltro={setDataFiltro}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      options={options}
                      filter={filter}
                      setFilter={setFilter}
                    />
                  )}

                  {activeTab === 5 && (
                    <SearchRespostas
                      dataFiltro={dataFiltro}
                      setDataFiltro={setDataFiltro}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      options={options}
                      filter={filter}
                      setFilter={setFilter}
                    />
                  )}

                  {activeTab === 6 && tabReturn === 0 && (
                    <SearchQuestionarioAreaPergunta
                      dataFiltro={dataFiltro}
                      setDataFiltro={setDataFiltro}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      options={options}
                      filter={filter}
                      setFilter={setFilter}
                    />
                  )}

                  {activeTab === 6 &&
                    tabReturn === 1 &&
                    isAdicionarPergunta && (
                      <SearchAdicionarPergunta
                        dataFiltro={dataFiltro}
                        setDataFiltro={setDataFiltro}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        options={options}
                        filter={filter}
                        setFilter={setFilter}
                      />
                    )}

                  {activeTab === 2 &&
                    tabReturn === 1 &&
                    isAdicionarPergunta && (
                      <SearchSubareaPerguntas
                        dataFiltro={dataFiltro}
                        setDataFiltro={setDataFiltro}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        options={options}
                        filter={filter}
                        setFilter={setFilter}
                      />
                    )}
                </div>
              </div>
            )}

            <div className="nav nav-tabs" style={{ marginTop: '-1rem' }}>
              <p style={{ display: 'none' }}>Área de Auditoria</p>
              <p style={{ display: 'none' }}>Sub Área de Auditoria</p>
              <p style={{ display: 'none' }}>Ambiente</p>
              <p style={{ display: 'none' }}>Perguntas</p>
              <p style={{ display: 'none' }}>Respostas</p>
              <p style={{ display: 'none' }}>Questionário Área Pergunta</p>
            </div>

            {activeTab === 0 && (
              <div style={{ width: '100%' }}>
                <div className="row">
                  <div
                    className="col-sm-12 col-md-4 col-lg-3 mb-1"
                    onClick={() => {
                      setTitle('da Área de Auditoria');
                      setActiveTab(1);
                    }}
                  >
                    <div className="card">
                      <div className="card-body d-flex flex-column justify-content-center align-items-center">
                        <h4 style={{ fontSize: props.tamSubTitulo }}>
                          Área de Auditoria
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-sm-12 col-md-4 col-lg-3 mb-1"
                    onClick={() => {
                      setTitle('da Subarea de Auditoria');
                      setActiveTab(2);
                    }}
                  >
                    <div className="card">
                      <div className="card-body d-flex flex-column justify-content-center align-items-center">
                        <h4 style={{ fontSize: props.tamSubTitulo }}>
                          Subarea de Auditoria
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-sm-12 col-md-4 col-lg-3 mb-1"
                    onClick={() => {
                      setTitle('das Perguntas');
                      setActiveTab(4);
                    }}
                  >
                    <div className="card">
                      <div className="card-body d-flex flex-column justify-content-center align-items-center">
                        <h4 style={{ fontSize: props.tamSubTitulo }}>
                          Perguntas
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-sm-12 col-md-4 col-lg-3 mb-1"
                    onClick={() => {
                      setTitle('das Respostas');
                      setActiveTab(5);
                    }}
                  >
                    <div className="card">
                      <div className="card-body d-flex flex-column justify-content-center align-items-center">
                        <h4 style={{ fontSize: props.tamSubTitulo }}>
                          Respostas
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-sm-12 col-md-4 col-lg-3 mb-1"
                    onClick={() => {
                      setTitle(
                        'Criação e Manutenção do Ambiente (Questionário)'
                      );
                      setActiveTab(3);
                    }}
                  >
                    <div className="card">
                      <div className="card-body d-flex flex-column justify-content-center align-items-center">
                        <h4 style={{ fontSize: props.tamSubTitulo }}>
                          Ambiente (Quest.)
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-sm-12 col-md-4 col-lg-3 mb-1"
                    onClick={() => {
                      setTitle('Perguntas do Ambiente');
                      setActiveTab(6);
                    }}
                  >
                    <div className="card">
                      <div className="card-body d-flex flex-column justify-content-center align-items-center">
                        <h4 style={{ fontSize: props.tamSubTitulo }}>
                          Perguntas do Ambiente
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-sm-12 col-md-4 col-lg-3 mb-1"
                    onClick={() => {
                      setTitle('Questionário Área');
                      setActiveTab(7);
                    }}
                  >
                    <div className="card">
                      <div className="card-body d-flex flex-column justify-content-center align-items-center">
                        <h4 style={{ fontSize: props.tamSubTitulo }}>
                          Área do Ambiente
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="tab-content">
              <div
                className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}
                id="tab1"
              >
                <Area
                  tamTexto={props.tamTexto}
                  tamSubTexto={props.tamSubTexto}
                  tabInsert={tabInsert}
                  setTabInsert={setTabInsert}
                  tabReturn={tabReturn}
                  setTabReturn={setTabReturn}
                  tabUpdate={tabUpdate}
                  setTabUpdate={setTabUpdate}
                  setNvAcesso={setNvAcesso}
                  title={title}
                  setTitle={setTitle}
                />
              </div>
              <div
                className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}
                id="tab2"
              >
                <Subarea
                  tamTexto={props.tamTexto}
                  tamSubTexto={props.tamSubTexto}
                  tabInsert={tabInsert}
                  setTabInsert={setTabInsert}
                  tabReturn={tabReturn}
                  setTabReturn={setTabReturn}
                  tabUpdate={tabUpdate}
                  setTabUpdate={setTabUpdate}
                  setNvAcesso={setNvAcesso}
                  activeTab={activeTab}
                  dataFiltro={dataFiltro}
                  filter={filter}
                  title={title}
                  setTitle={setTitle}
                  setIsAdicionarPergunta={setIsAdicionarPergunta}
                />
              </div>
              <div
                className={`tab-pane ${activeTab === 3 ? 'active' : ''}`}
                id="tab3"
              >
                <Ambiente
                  tamTexto={props.tamTexto}
                  tamSubTexto={props.tamSubTexto}
                  tabInsert={tabInsert}
                  setTabInsert={setTabInsert}
                  tabReturn={tabReturn}
                  setTabReturn={setTabReturn}
                  tabUpdate={tabUpdate}
                  setTabUpdate={setTabUpdate}
                  setNvAcesso={setNvAcesso}
                  activeTab={activeTab}
                  dataFiltro={dataFiltro}
                  filter={filter}
                  options={options}
                  title={title}
                  setTitle={setTitle}
                />
              </div>
              <div
                className={`tab-pane ${activeTab === 4 ? 'active' : ''}`}
                id="tab4"
              >
                <Perguntas
                  tamTexto={props.tamTexto}
                  tamSubTexto={props.tamSubTexto}
                  tabInsert={tabInsert}
                  setTabInsert={setTabInsert}
                  tabReturn={tabReturn}
                  setTabReturn={setTabReturn}
                  tabUpdate={tabUpdate}
                  setTabUpdate={setTabUpdate}
                  setNvAcesso={setNvAcesso}
                  activeTab={activeTab}
                  dataFiltro={dataFiltro}
                  filter={filter}
                  title={title}
                  setTitle={setTitle}
                />
              </div>
              <div
                className={`tab-pane ${activeTab === 5 ? 'active' : ''}`}
                id="tab5"
              >
                <Respostas
                  tamTexto={props.tamTexto}
                  tamSubTexto={props.tamSubTexto}
                  tabInsert={tabInsert}
                  setTabInsert={setTabInsert}
                  tabReturn={tabReturn}
                  setTabReturn={setTabReturn}
                  tabUpdate={tabUpdate}
                  setTabUpdate={setTabUpdate}
                  setNvAcesso={setNvAcesso}
                  activeTab={activeTab}
                  dataFiltro={dataFiltro}
                  filter={filter}
                  title={title}
                  setTitle={setTitle}
                />
              </div>
              <div
                className={`tab-pane ${activeTab === 6 ? 'active' : ''}`}
                id="tab6"
              >
                <QuestionarioAreaPergunta
                  tamTexto={props.tamTexto}
                  tamSubTexto={props.tamSubTexto}
                  tabInsert={tabInsert}
                  setTabInsert={setTabInsert}
                  tabReturn={tabReturn}
                  setTabReturn={setTabReturn}
                  tabUpdate={tabUpdate}
                  setTabUpdate={setTabUpdate}
                  setNvAcesso={setNvAcesso}
                  activeTab={activeTab}
                  dataFiltro={dataFiltro}
                  filter={filter}
                  setCopy={setCopy}
                  title={title}
                  setTitle={setTitle}
                  setIsAdicionarPergunta={setIsAdicionarPergunta}
                />
              </div>
              <div
                className={`tab-pane ${activeTab === 7 ? 'active' : ''}`}
                id="tab7"
              >
                <QuestionarioArea
                  tamTexto={props.tamTexto}
                  tamSubTexto={props.tamSubTexto}
                  tabInsert={tabInsert}
                  setTabInsert={setTabInsert}
                  tabReturn={tabReturn}
                  setTabReturn={setTabReturn}
                  tabUpdate={tabUpdate}
                  setTabUpdate={setTabUpdate}
                  setNvAcesso={setNvAcesso}
                  activeTab={activeTab}
                  dataFiltro={dataFiltro}
                  filter={filter}
                  title={title}
                  setTitle={setTitle}
                />
              </div>
            </div>
          </div>

          <div className="modal-footer">
            {activeTab > 0 && (
              <>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    if (tabReturn === 2) {
                      setTabReturn(3);
                    } else if (tabReturn > 0) {
                      setTabReturn(0);
                      const texto = title.split(' - ');
                      setTitle(texto[0]);
                    } else {
                      setTitle('');
                      setActiveTab(0);
                      setDataFiltro({});
                    }
                    setTabInsert(0);
                    setTabUpdate(0);
                  }}
                  style={{ fontSize: props.tamTexto }}
                >
                  <i className="fa-solid fa-right-from-bracket fa-rotate-180"></i>
                </button>

                {activeTab === 6 && tabReturn === 1 && isAdicionarPergunta && (
                  <button
                    className="btn btn-primary"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasTransferencia"
                    aria-controls="offcanvasExample"
                    style={{ fontSize: props.tamTexto }}
                  >
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </button>
                )}

                {activeTab === 2 && tabReturn === 1 && isAdicionarPergunta && (
                  <button
                    className="btn btn-primary"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasTransferencia"
                    aria-controls="offcanvasExample"
                    style={{ fontSize: props.tamTexto }}
                  >
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </button>
                )}

                {tabReturn === 0 && (
                  <>
                    {activeTab > 1 && activeTab !== 7 && (
                      <button
                        className="btn btn-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasTransferencia"
                        aria-controls="offcanvasExample"
                        style={{ fontSize: props.tamTexto }}
                      >
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </button>
                    )}

                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        setTabInsert(activeTab);
                      }}
                      style={{ fontSize: props.tamTexto }}
                    >
                      <i className="fa-solid fa-plus"></i>
                    </button>
                  </>
                )}

                {tabReturn === 1 && nvAcesso !== 'read' && activeTab !== 6 && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      setTabUpdate(activeTab);
                    }}
                    style={{ fontSize: props.tamTexto }}
                  >
                    <i className="fa-solid fa-check"></i>
                  </button>
                )}

                {tabReturn === 1 && nvAcesso !== 'read' && activeTab === 6 && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      setTabUpdate(activeTab);
                    }}
                    style={{ fontSize: props.tamTexto }}
                  >
                    {copy === 2 ? (
                      <i className="fa-solid fa-copy"></i>
                    ) : (
                      <i className="fa-solid fa-check"></i>
                    )}
                  </button>
                )}

                {/* {tabReturn === 2 && nvAcesso !== 'read' && activeTab === 6 && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      setTabUpdate(activeTab);
                    }}
                    style={{ fontSize: props.tamTexto }}
                  >
                    <i className="fa-solid fa-check"></i>
                  </button>
                )} */}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
