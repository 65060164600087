export const SearchQuestionarioAreaPergunta = (props) => {
  return (
    <>
      <div>
        <span className="col-form-label">Área</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.area || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                area: e.target.value
              })
            }
          >
            <option defaultValue></option>
            {props.options[0]
              ?.slice()
              .sort((a, b) => a.are_descri.localeCompare(b.are_descri))
              .map((option, index) => (
                <option key={index} value={option.are_codare}>
                  {option.are_codare} - {option.are_descri}
                </option>
              ))}
          </select>
        </div>
      </div>

      <div>
        <span className="col-form-label">Subarea</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.subarea || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                subarea: e.target.value
              })
            }
          >
            <option defaultValue></option>
            {props.options[4]
              ?.filter(
                (item) => item.sar_codare === (props.dataFiltro?.area || '')
              )
              .slice()
              .sort((a, b) => a.sar_descri.localeCompare(b.sar_descri))
              .map((option, index) => (
                <option key={index} value={option.sar_codsar}>
                  {option.sar_codsar} - {option.sar_descri}
                </option>
              ))}
          </select>
        </div>
      </div>

      <div>
        <span className="col-form-label">Ambiente</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.questionario || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                questionario: e.target.value
              })
            }
          >
            <option defaultValue></option>
            {props.options[6]
              ?.filter(
                (item) => item.codigoArea === (props.dataFiltro?.area || '')
              )
              .slice()
              .sort((a, b) => a.descricao.localeCompare(b.descricao))
              .map((option, index) => (
                <option key={index} value={option.codigo}>
                  {option.codigo} - {option.descricao}
                </option>
              ))}
          </select>
        </div>
      </div>

      {/* button */}
      <div className="row mt-2">
        <div className="col-12 d-flex align-items-center justify-content-end">
          <button
            className="btn btn-primary"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => props.setFilter(props.filter + 1)}
          >
            <i className="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
      </div>
    </>
  );
};
