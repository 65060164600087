import {
  handleFirstPage,
  handleLastPage,
  handlePageClick,
  handleRowClick
} from '../../functions';

const BlcTitle = (props) => {
  return (
    <th scope="col">
      <div className="d-flex justify-content-between align-items-center">
        <span style={{ fontSize: props.tamSubTexto }}>{props.title}</span>
        <div className="ms-1 text-nowrap">
          <span
            className="me-1"
            onClick={() => props.handleSort(props.order, 'asc')}
          >
            <i className="fa-solid fa-arrow-up"></i>
          </span>
          <span onClick={() => props.handleSort(props.order, 'desc')}>
            <i className="fa-solid fa-arrow-down"></i>
          </span>
        </div>
      </div>
    </th>
  );
};

export const CadNovo = (props) => {
  const campos = [
    { title: 'Cód. da Pergunta', order: 'psa_codper' },
    { title: 'Descrição da Pergunta', order: 'per_descri' },
    { title: 'Abreviatura', order: 'per_abrevi' }
  ];

  return (
    <div>
      <div className="row">
        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Cód. Área</span>
          </div>
          <div className="col-9">
            <span className="col-form-label">Desc. Área</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              value={props.data?.codigoArea || ''}
              onChange={props.handleInput}
              disabled
            />
          </div>
          <div className="col-9">
            <select
              className="form-select form-select-sm border border-dark"
              name="area"
              value={props.data?.area || ''}
              onChange={(e) => {
                const filter = props.optionsArea?.filter((option) => {
                  return option.are_descri === e.target.value;
                });

                props.setData({
                  ...props.data,
                  codigoArea: filter[0]?.are_codare || '',
                  area: e.target.value,
                  codigoSubarea: '',
                  subarea: '',
                  perguntas: '',
                  perguntasDescricao: ''
                });
              }}
            >
              <option defaultValue></option>
              {props.optionsArea
                ?.sort((a, b) => a.are_descri.localeCompare(b.are_descri))
                .map((option, index) => (
                  <option key={index} value={option.are_descri}>
                    {option.are_descri}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Cód. Subarea</span>
          </div>
          <div className="col-9">
            <span className="col-form-label">Desc. Subarea</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              value={props.data?.codigoSubarea || ''}
              onChange={props.handleInput}
              disabled
            />
          </div>
          <div className="col-9">
            <select
              className="form-select form-select-sm border border-dark"
              name="subarea"
              value={props.data?.subarea || ''}
              onChange={(e) => {
                const filter = props.optionsSubarea?.filter((option) => {
                  return option.sar_descri === e.target.value;
                });

                props.setData({
                  ...props.data,
                  codigoSubarea: filter[0]?.sar_codsar || '',
                  subarea: e.target.value,
                  perguntas: '',
                  perguntasDescricao: ''
                });
              }}
              disabled={
                (props.data?.codigoArea === '' ||
                  props.data?.codigoArea === undefined ||
                  props.data?.codigoArea === null) &&
                true
              }
            >
              <option defaultValue></option>
              {props.optionsSubarea
                ?.filter((item) => item.sar_codare === props.data?.codigoArea)
                .sort((a, b) => a.sar_descri.localeCompare(b.sar_descri))
                .map((option, index) => (
                  <option key={index} value={option.sar_descri}>
                    {option.sar_descri}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-3">
            <span className="col-form-label">Cód. Ambiente</span>
          </div>
          <div className="col-9">
            <span className="col-form-label">Desc. Ambiente</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              value={props.data?.codigoQuestionario || ''}
              onChange={props.handleInput}
              disabled
            />
          </div>
          <div className="col-9">
            <select
              className="form-select form-select-sm border border-dark"
              name="questionario"
              value={props.data?.questionario || ''}
              onChange={(e) => {
                const filter = props.optionsQuestionario?.filter((option) => {
                  return option.descricao === e.target.value;
                });

                props.setData({
                  ...props.data,
                  codigoQuestionario: filter[0]?.codigo || '',
                  questionario: e.target.value,
                  perguntas: '',
                  perguntasDescricao: ''
                });
              }}
            >
              <option defaultValue></option>
              {props.optionsQuestionarioNovo
                ?.sort((a, b) => a.descricao.localeCompare(b.descricao))
                .map((option, index) => (
                  <option key={index} value={option.descricao}>
                    {option.descricao}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Descrição</span>
          </div>
          <div className="col-3">
            <span className="col-form-label"></span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              value={props.data?.descricaoPergunta || ''}
              onChange={(e) =>
                props.setData({
                  ...props.data,
                  descricaoPergunta: e.target.value
                })
              }
              style={{ textTransform: 'uppercase' }}
            />
          </div>
          <div className="col-3">
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => props.handlePerguntaExistentes()}
              style={{ fontSize: props.tamTexto }}
            >
              <i className="fa-solid fa-magnifying-glass"></i>
            </button>
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-6">
            <span className="col-form-label">Abreviatura</span>
          </div>
          <div className="col-3">
            <span className="col-form-label"></span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              value={props.data?.abreviatura || ''}
              onChange={(e) =>
                props.setData({ ...props.data, abreviatura: e.target.value })
              }
              style={{ textTransform: 'uppercase' }}
            />
          </div>
          <div className="col-3">
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => props.handlePerguntaExistentes()}
              style={{ fontSize: props.tamTexto }}
            >
              <i className="fa-solid fa-magnifying-glass"></i>
            </button>
          </div>
        </div>
      </div>

      <table className="table table-striped table-hover table-bordered table-sm align-middle mt-2">
        <thead>
          <tr className="table-secondary">
            {campos.map((campo) => (
              <BlcTitle
                key={campo.order}
                title={campo.title}
                order={campo.order}
                handleSort={props.handleSort}
                tamTexto={props.tamSubTexto}
              />
            ))}

            {/* <th scope="col" style={{ fontSize: props.tamTexto }}>
              Cód. da Pergunta
            </th>
            <th scope="col" style={{ fontSize: props.tamTexto }}>
              Descrição da Pergunta
            </th>
            <th scope="col" style={{ fontSize: props.tamTexto }}>
              Abreviatura
            </th> */}
            <th scope="col" style={{ fontSize: props.tamTexto, width: '80px' }}>
              Adicionar
            </th>
          </tr>
        </thead>
        <tbody>
          {props.filteredData?.map((item, index) => (
            <tr
              key={index}
              className={props.selectedRow === index ? 'bg-info' : ''}
              onClick={() => handleRowClick(index, props.setSelectedRow)}
            >
              <td scope="row" style={{ fontSize: props.tamSubTexto }}>
                {item.psa_codper}
              </td>
              <td style={{ fontSize: props.tamSubTexto }}>{item.per_descri}</td>
              <td style={{ fontSize: props.tamSubTexto }}>{item.per_abrevi}</td>
              <td>
                <div>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={props.data.perguntas?.includes(item.psa_codper)}
                    onChange={() => {
                      const updatedPerguntas = props.data.perguntas?.includes(
                        item.psa_codper
                      )
                        ? props.data.perguntas.filter(
                            (id) => id !== item.psa_codper
                          )
                        : [...(props.data.perguntas || ''), item.psa_codper];

                      const updatedPerguntasDescricao =
                        props.data.perguntas?.includes(item.per_descri)
                          ? props.data.perguntasDescricao.filter(
                              (id) => id !== item.per_descri
                            )
                          : [
                              ...(props.data.perguntasDescricao || ''),
                              item.per_descri
                            ];

                      props.setData({
                        ...props.data,
                        perguntas: updatedPerguntas,
                        perguntasDescricao: updatedPerguntasDescricao
                      });
                    }}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="4" className="align-middle">
              <nav className="d-flex align-items-center justify-content-center">
                <ul className="pagination">
                  <li className="page-item">
                    <p
                      className="page-link"
                      aria-label="PreviousPage"
                      onClick={() =>
                        handleFirstPage(
                          props.setCurrentPage,
                          props.setSelectedRow
                        )
                      }
                    >
                      <span
                        aria-hidden="true"
                        style={{ fontSize: props.tamTexto }}
                      >
                        &laquo;
                      </span>
                    </p>
                  </li>
                  <li className="page-item">
                    <p
                      className="page-link"
                      aria-label="PreviousIten"
                      onClick={() =>
                        handlePageClick(
                          props.currentPage - 1,
                          props.pageCount,
                          props.setCurrentPage,
                          props.setSelectedRow
                        )
                      }
                    >
                      <span
                        aria-hidden="true"
                        style={{ fontSize: props.tamTexto }}
                      >
                        &lt;
                      </span>
                    </p>
                  </li>
                  {props.displayedPageNumbers.map((pageNumber) => (
                    <li
                      key={pageNumber}
                      className={`page-item ${
                        pageNumber === props.currentPage ? 'active' : ''
                      }`}
                      style={{ fontSize: props.tamTexto }}
                    >
                      <button
                        className="page-link"
                        onClick={() =>
                          handlePageClick(
                            pageNumber,
                            props.pageCount,
                            props.setCurrentPage,
                            props.setSelectedRow
                          )
                        }
                      >
                        {pageNumber + 1}
                      </button>
                    </li>
                  ))}
                  <li className="page-item">
                    <p
                      className="page-link"
                      aria-label="NextIten"
                      onClick={() =>
                        handlePageClick(
                          props.currentPage + 1,
                          props.pageCount,
                          props.setCurrentPage,
                          props.setSelectedRow
                        )
                      }
                    >
                      <span
                        aria-hidden="true"
                        style={{ fontSize: props.tamTexto }}
                      >
                        &gt;
                      </span>
                    </p>
                  </li>
                  <li className="page-item">
                    <p
                      className="page-link"
                      aria-label="NextPage"
                      onClick={() =>
                        handleLastPage(
                          props.setCurrentPage,
                          props.setSelectedRow,
                          props.pageCount
                        )
                      }
                    >
                      <span
                        aria-hidden="true"
                        style={{ fontSize: props.tamTexto }}
                      >
                        &raquo;
                      </span>
                    </p>
                  </li>
                </ul>
                <div className="d-flex flex-column">
                  <p
                    className="text-muted ms-2"
                    style={{ fontSize: props.tamTexto }}
                  >
                    Quantidade de Registros: {props.contPergunta}
                  </p>
                </div>
              </nav>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
