import { useCallback, useEffect, useState } from 'react';

import { ApiGet } from '../services/apiService';
import ChartContainer from './graficos/ChartContainer';
import { GraficoBarraLinhaPeriodos } from './graficos/GraficoBarraLinhaPeriodos';

export const Modal3 = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  const pastDate = new Date();
  pastDate.setMonth(currentDate.getMonth() - 3);
  const pastYear = pastDate.getFullYear();
  const pastMonth = pastDate.getMonth() + 1;

  const formatDate = (year, month) => {
    return `${year}${month.toString().padStart(2, '0')}`;
  };

  const initialDataInicial = formatDate(pastYear, pastMonth);
  const initialDataFinal = formatDate(currentYear, currentMonth);
  const [seq, setSeq] = useState(['']);
  const [dataInicial, setDataInicial] = useState(initialDataInicial);
  const [dataFinal, setDataFinal] = useState(initialDataFinal);
  const [graficoBarraData, setGraficoBarraData] = useState([]);

  const [unidadeAdm, setUnidadeAdm] = useState([]);
  const handleUnidadeAdm = async () => {
    try {
      const response = await ApiGet(
        '/tabela/unidadeAdm',
        {},
        localStorage.getItem('sessionToken')
      );
      let arrayUnidadeAdm = response.data.tabela.map((item) => ({
        value: item.codigo_unidade_adm,
        label: item.nome_unidade_adm
      }));
      setUnidadeAdm(arrayUnidadeAdm);
    } catch (err) {
      console.log(err);
    }
  };
  const prevSeqOptions = unidadeAdm
    .slice()
    .sort((a, b) => a.value.localeCompare(b.value));

  const seqOptions = prevSeqOptions.map((option, index) => {
    return { item: `${option.value} - ${option.label}` };
  });

  const handleSearch = useCallback(async () => {
    try {
      let useSeq;
      if (!seq) {
        useSeq = prevSeqOptions[0].que_seqque;
      } else {
        useSeq = seq;
      }
      const response = await ApiGet(
        '/auditoria/grafico/v1/ambientePorUnidadeAdm',
        {
          unidadeAdm: useSeq,
          dataInicio: dataInicial,
          dataFim: dataFinal
        },
        localStorage.getItem('sessionToken')
      );
      const newData = response.data?.auditoria?.map((item) => ({
        data_id: item.anomes,
        id: item.uad_nomuad,
        value: item.media_quest
      }));
      return newData || [];
    } catch (err) {
      console.log(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seq, dataInicial, dataFinal]);

  useEffect(() => {
    handleSearch().then((data) => {
      setGraficoBarraData(data);
    });
  }, [handleSearch]);
  useEffect(() => {
    handleUnidadeAdm();
  }, []);

  return (
    <>
      <div
        className="modal fade modal-xl"
        id="ModalGrafico3"
        tabIndex="-1"
        aria-labelledby="lblGrafico1"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-scrollable modalDialog">
          <div className="modal-content">
            <div className="modal-header" style={{ height: '3rem' }}>
              <div className="d-flex justify-content-botween align-items-center w-100">
                <i
                  className="fa-solid fa-arrow-left fs-5"
                  data-bs-toggle="modal"
                  data-bs-target="#ModalLista"
                ></i>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
            </div>

            <ChartContainer
              seqOptions={seqOptions}
              setSeq={setSeq}
              dataInicialFormatted={String(dataInicial)}
              dataFinalFormatted={String(dataFinal)}
              setDataInicial={setDataInicial}
              setDataFinal={setDataFinal}
              title="Unidade Adm:"
              isMany={true}
            >
              <GraficoBarraLinhaPeriodos
                dataForm={graficoBarraData}
                meta={parseInt(localStorage.getItem('sessionMeta'))}
                mesIni={String(dataInicial).slice(4, 6)}
                anoIni={String(dataInicial).slice(0, 4)}
                mesFim={String(dataFinal).slice(4, 6)}
                anoFim={String(dataFinal).slice(0, 4)}
                titulo={
                  'Media de nota de Ambientes por Unidade Adm/Equipe no periodo'
                }
              />
            </ChartContainer>
          </div>
        </div>
      </div>
    </>
  );
};
