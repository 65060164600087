import { CadCopia } from './CadCopia';
import { CadNovo } from './CadNovo';
import { Grid } from './Grid';
import { GridAmbienteNotPergunta } from './GridAmbienteNotPergunta';
import { GridPerguntaQuestionario } from './GridPerguntaQuestionario';
import { GridPerguntas } from './GridPerguntas';
import { GridQuestionario } from './GridQuestionario';
import { GridQuestionarioPergunta } from './GridQuestionarioPergunta';

export const Cadastro = (props) => {
  const isActiveTab =
    props.activeTabCadastro === 1 || props.activeTabCadastro === 2;

  const isActiveTabEditar =
    props.activeTabCadastro === 4 || props.activeTabCadastro === 5;

  return (
    <>
      <div className="nav nav-tabs">
        {props.insertOrUpdate === 'insert' && (
          <>
            <p
              className={`nav-link ${
                props.activeTabCadastro === 1
                  ? 'text-primary active'
                  : 'text-dark'
              }`}
              onClick={() => {
                props.setCopy(1);
                props.setActiveTabCadastro(1);
                props.setIsAdicionarPergunta(false);
              }}
              style={
                isActiveTab ? { fontSize: props.tamTexto } : { display: 'none' }
              }
            >
              Inclusão/Nova
            </p>
            <p
              className={`nav-link ${
                props.activeTabCadastro === 2
                  ? 'text-primary active'
                  : 'text-dark'
              }`}
              onClick={() => {
                props.setCopy(2);
                props.setActiveTabCadastro(2);
                props.setIsAdicionarPergunta(false);
              }}
              style={
                isActiveTab ? { fontSize: props.tamTexto } : { display: 'none' }
              }
            >
              Inclusão/Cópia
            </p>
          </>
        )}
        {props.insertOrUpdate !== 'insert' && (
          <>
            <p
              className={`nav-link ${
                props.activeTabCadastro === 4
                  ? 'text-primary active'
                  : 'text-dark'
              }`}
              onClick={() => {
                props.setCopy(4);
                props.setActiveTabCadastro(4);
                props.setIsAdicionarPergunta(false);
              }}
              style={
                isActiveTabEditar && props.insertOrUpdate !== 'read'
                  ? { fontSize: props.tamTexto }
                  : { display: 'none' }
              }
            >
              Perguntas Incluídas
            </p>

            {props.insertOrUpdate !== 'read' && (
              <p
                className={`nav-link ${
                  props.activeTabCadastro === 5
                    ? 'text-primary active'
                    : 'text-dark'
                }`}
                onClick={() => {
                  props.setCopy(5);
                  props.setActiveTabCadastro(5);
                  props.setIsAdicionarPergunta(true);
                }}
                style={
                  isActiveTabEditar
                    ? { fontSize: props.tamTexto }
                    : { display: 'none' }
                }
              >
                Adicionar Mais Perguntas
              </p>
            )}
          </>
        )}
        {props.isQuestionarioArea && (
          <p style={{ display: 'none' }}>Escolher Questionario</p>
        )}
      </div>

      <div className="tab-content">
        {props.insertOrUpdate === 'insert' && (
          <>
            <div
              className={`tab-pane ${
                props.activeTabCadastro === 1 ? 'active' : ''
              }`}
              id="tabCadastro1"
            >
              <CadNovo
                data={props.data}
                setData={props.setData}
                insertOrUpdate={props.insertOrUpdate}
                optionsArea={props.optionsArea}
                optionsSubarea={props.optionsSubarea}
                optionsQuestionario={props.optionsQuestionario}
                optionsQuestionarioNovo={props.optionsQuestionarioNovo}
                //
                filteredData={props.filteredData}
                selectedRow={props.selectedRow}
                setSelectedRow={props.setSelectedRow}
                currentPage={props.currentPage}
                setCurrentPage={props.setCurrentPage}
                pageCount={props.pageCount}
                displayedPageNumbers={props.displayedPageNumbers}
                tamTexto={props.tamTexto}
                tamSubTexto={props.tamSubTexto}
                setActiveTabCadastro={props.setActiveTabCadastro}
                grid={props.grid}
                handleSave={props.handleSave}
                contPergunta={props.contPergunta}
                handlePerguntaExistentes={props.handlePerguntaExistentes}
                handleSort={props.handleSortPergunta}
              />
            </div>
            <div
              className={`tab-pane ${
                props.activeTabCadastro === 2 ? 'active' : ''
              }`}
              id="tabCadastro2"
            >
              <CadCopia
                data={props.data}
                setData={props.setData}
                optionsQuestionario={props.optionsQuestionario}
                optionsQuestionarioNovo={props.optionsQuestionarioNovo}
                optionsQuestionarioNovoCopia={
                  props.optionsQuestionarioNovoCopia
                }
              />
            </div>
          </>
        )}

        <div
          className={`tab-pane ${
            props.activeTabCadastro === 3 ? 'active' : ''
          }`}
          id="tabCadastro3"
        >
          <GridQuestionario
            title={props.title}
            setTitle={props.setTitle}
            data={props.data}
            setData={props.setData}
            tamTexto={props.tamTexto}
            tamSubTexto={props.tamSubTexto}
            filteredData={props.filteredDataQuestionario}
            selectedRow={props.selectedRowQuestionario}
            setSelectedRow={props.setSelectedRowQuestionario}
            currentPage={props.currentPageQuestionario}
            setCurrentPage={props.setCurrentPageQuestionario}
            pageCount={props.pageCountQuestionario}
            displayedPageNumbers={props.displayedPageNumbersQuestionario}
            contQuestionario={props.contQuestionario}
            setActiveTabCadastro={props.setActiveTabCadastro}
            setTabReturn={props.setTabReturn}
            insertOrUpdate={props.insertOrUpdate}
            // dataEscolherPergunta={props.dataEscolherPergunta}
            // setDataEscolherPergunta={props.setDataEscolherPergunta}
            setActiveTabQuesPer={props.setActiveTabQuesPer}
            activeTabQuesPer={props.activeTabQuesPer}
            filteredDataEscolherPergunta={props.filteredDataEscolherPergunta}
            selectedRowEscolherPergunta={props.selectedRowEscolherPergunta}
            setSelectedRowEscolherPergunta={
              props.setSelectedRowEscolherPergunta
            }
            currentPageEscolherPergunta={props.currentPageEscolherPergunta}
            setCurrentPageEscolherPergunta={
              props.setCurrentPageEscolherPergunta
            }
            pageCountEscolherPergunta={props.pageCountEscolherPergunta}
            displayedPageNumbersEscolherPergunta={
              props.displayedPageNumbersEscolherPergunta
            }
            contEscolherPergunta={props.contEscolherPergunta}
            setIsPerguntaOrAmbiente={props.setIsPerguntaOrAmbiente}
            handleAmbienteExistentes={props.handleAmbienteExistentes}
            setPerguntaSelecionada={props.setPerguntaSelecionada}
          />
        </div>

        {props.insertOrUpdate !== 'insert' && (
          <>
            {props.isPerguntaOrAmbiente === 'ambiente' && (
              <>
                <div
                  className={`tab-pane ${
                    props.activeTabCadastro === 4 ? 'active' : ''
                  }`}
                  id="tabCadastro4"
                >
                  <Grid
                    data={props.data}
                    setData={props.setData}
                    insertOrUpdate={props.insertOrUpdate}
                    optionsArea={props.optionsArea}
                    optionsSubarea={props.optionsSubarea}
                    optionsPergunta={props.optionsPergunta}
                    filteredData={props.filteredDataPerguntaExistentes}
                    selectedRow={props.selectedRowPerguntaExistentes}
                    setSelectedRow={props.setSelectedRowPerguntaExistentes}
                    currentPage={props.currentPagePerguntaExistentes}
                    setCurrentPage={props.setCurrentPagePerguntaExistentes}
                    pageCount={props.pageCountPerguntaExistentes}
                    displayedPageNumbers={
                      props.displayedPageNumbersPerguntaExistentes
                    }
                    tamTexto={props.tamTexto}
                    tamSubTexto={props.tamSubTexto}
                    contPerguntaExistentes={props.contPerguntaExistentes}
                    handlePerguntaExistentes={props.handlePerguntaExistentes}
                    setIsPerguntaOrAmbiente={props.setIsPerguntaOrAmbiente}
                    isPerguntaOrAmbiente={props.isPerguntaOrAmbiente}
                  />
                </div>
                {props.insertOrUpdate !== 'read' && (
                  <div
                    className={`tab-pane ${
                      props.activeTabCadastro === 5 ? 'active' : ''
                    }`}
                    id="tabCadastro5"
                  >
                    <GridPerguntas
                      data={props.data}
                      setData={props.setData}
                      insertOrUpdate={props.insertOrUpdate}
                      optionsArea={props.optionsArea}
                      optionsSubarea={props.optionsSubarea}
                      filteredData={props.filteredData}
                      selectedRow={props.selectedRow}
                      setSelectedRow={props.setSelectedRow}
                      currentPage={props.currentPage}
                      setCurrentPage={props.setCurrentPage}
                      pageCount={props.pageCount}
                      displayedPageNumbers={props.displayedPageNumbers}
                      tamTexto={props.tamTexto}
                      tamSubTexto={props.tamSubTexto}
                      setActiveTabCadastro={props.setActiveTabCadastro}
                      grid={props.grid}
                      handleSave={props.handleSave}
                      contPergunta={props.contPergunta}
                      setIsPerguntaOrAmbiente={props.setIsPerguntaOrAmbiente}
                      isPerguntaOrAmbiente={props.isPerguntaOrAmbiente}
                    />
                  </div>
                )}
              </>
            )}

            {props.isPerguntaOrAmbiente === 'pergunta' && (
              <>
                <div
                  className={`tab-pane ${
                    props.activeTabCadastro === 4 ? 'active' : ''
                  }`}
                  id="tabCadastro4"
                >
                  <GridPerguntaQuestionario
                    data={props.data}
                    setData={props.setData}
                    insertOrUpdate={props.insertOrUpdate}
                    optionsArea={props.optionsArea}
                    optionsSubarea={props.optionsSubarea}
                    optionsPergunta={props.optionsPergunta}
                    filteredData={props.filteredDataAmbienteExistentes}
                    selectedRow={props.selectedRowAmbienteExistentes}
                    setSelectedRow={props.setSelectedRowAmbienteExistentes}
                    currentPage={props.currentPageAmbienteExistentes}
                    setCurrentPage={props.setCurrentPageAmbienteExistentes}
                    pageCount={props.pageCountAmbienteExistentes}
                    displayedPageNumbers={
                      props.displayedPageNumbersAmbienteExistentes
                    }
                    tamTexto={props.tamTexto}
                    tamSubTexto={props.tamSubTexto}
                    contAmbienteExistentes={props.contAmbienteExistentes}
                    handlePerguntaExistentes={props.handlePerguntaExistentes}
                    setIsPerguntaOrAmbiente={props.setIsPerguntaOrAmbiente}
                    isPerguntaOrAmbiente={props.isPerguntaOrAmbiente}
                    perguntaSelecionada={props.perguntaSelecionada}
                  />
                </div>
                {props.insertOrUpdate !== 'read' && (
                  <div
                    className={`tab-pane ${
                      props.activeTabCadastro === 5 ? 'active' : ''
                    }`}
                    id="tabCadastro5"
                  >
                    <GridAmbienteNotPergunta
                      data={props.data}
                      setData={props.setData}
                      insertOrUpdate={props.insertOrUpdate}
                      optionsArea={props.optionsArea}
                      optionsSubarea={props.optionsSubarea}
                      filteredData={props.filteredDataAmbienteNotPergunta}
                      selectedRow={props.selectedRowAmbienteNotPergunta}
                      setSelectedRow={props.setSelectedRowAmbienteNotPergunta}
                      currentPage={props.currentPageAmbienteNotPergunta}
                      setCurrentPage={props.setCurrentPageAmbienteNotPergunta}
                      pageCount={props.pageCountAmbienteNotPergunta}
                      displayedPageNumbers={
                        props.displayedPageNumbersAmbienteNotPergunta
                      }
                      tamTexto={props.tamTexto}
                      tamSubTexto={props.tamSubTexto}
                      setActiveTabCadastro={props.setActiveTabCadastro}
                      grid={props.grid}
                      handleSave={props.handleSave}
                      contAmbienteNotPergunta={props.contAmbienteNotPergunta}
                      setIsPerguntaOrAmbiente={props.setIsPerguntaOrAmbiente}
                      isPerguntaOrAmbiente={props.isPerguntaOrAmbiente}
                      perguntaSelecionada={props.perguntaSelecionada}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}

        {props.isQuestionarioArea && (
          <div
            className={`tab-pane ${
              props.activeTabCadastro === 6 ? 'active' : ''
            }`}
            id="tabCadastro6"
          >
            <GridQuestionarioPergunta
              data={props.data}
              setData={props.setData}
              insertOrUpdate={props.insertOrUpdate}
              filteredData={props.filteredDataQuestionarioArea}
              selectedRow={props.selectedRowQuestionarioArea}
              setSelectedRow={props.setSelectedRowQuestionarioArea}
              currentPage={props.currentPageQuestionarioArea}
              setCurrentPage={props.setCurrentPageQuestionarioArea}
              pageCount={props.pageCountQuestionarioArea}
              displayedPageNumbers={props.displayedPageNumbersQuestionarioArea}
              contRegistros={props.contQuestionarioArea}
              tamTexto={props.tamTexto}
              tamSubTexto={props.tamSubTexto}
              handleEditQuestionarioArea={props.handleEditQuestionarioArea}
            />
          </div>
        )}
      </div>
    </>
  );
};
