export const SearchAmbiente = (props) => {
  return (
    <>
      {/* emp */}
      <div>
        <span className="col-form-label">Empresa</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.empresa || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                empresa: e.target.value
              })
            }
          >
            <option defaultValue></option>
            {props.options[1]
              ?.slice()
              .sort((a, b) => a.emp_fantasia.localeCompare(b.emp_fantasia))
              .map((option, index) => (
                <option key={index} value={option.emp_codemp}>
                  {option.emp_codemp} - {option.emp_fantasia}
                </option>
              ))}
          </select>
        </div>
      </div>

      {/* loja */}
      <div>
        <span className="col-form-label">Loja</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.loja || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                loja: e.target.value
              })
            }
          >
            <option defaultValue></option>
            {props.options[2]
              ?.slice()
              .sort((a, b) => a.loj_denloj.localeCompare(b.loj_denloj))
              .map((option, index) => (
                <option key={index} value={option.loj_codloj}>
                  {option.loj_codloj} - {option.loj_denloj}
                </option>
              ))}
          </select>
        </div>
      </div>

      {/* undadm */}
      <div>
        <span className="col-form-label">Unidade Administrativa</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.unidade || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                unidade: e.target.value
              })
            }
          >
            <option defaultValue></option>
            {props.options[3]
              ?.slice()
              .sort((a, b) =>
                a.nome_unidade_adm.localeCompare(b.nome_unidade_adm)
              )
              .map((option, index) => (
                <option key={index} value={option.codigo_unidade_adm}>
                  {option.codigo_unidade_adm} - {option.nome_unidade_adm}
                </option>
              ))}
          </select>
        </div>
      </div>

      <div>
        <span className="col-form-label">Status</span>
        <div>
          <select
            className="form-select form-select-sm border border-dark"
            value={props.dataFiltro?.status || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                status: e.target.value
              })
            }
          >
            <option defaultValue></option>
            <option value="ATIVO">ATIVO</option>
            <option value="INATI">INATIVO</option>
          </select>
        </div>
      </div>

      {/* button */}
      <div className="row mt-2">
        <div className="col-12 d-flex align-items-center justify-content-end">
          <button
            className="btn btn-primary"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => props.setFilter(props.filter + 1)}
          >
            <i className="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
      </div>
    </>
  );
};
