export const SearchSubareaPerguntas = (props) => {
  return (
    <>
      <div>
        <span className="col-form-label">Abreviatura</span>
        <div>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.abreviacao || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                abreviacao: e.target.value.toUpperCase()
              })
            }
            style={{ textTransform: 'uppercase' }}
          />
        </div>
      </div>

      <div>
        <span className="col-form-label">Palavra Chave</span>
        <div>
          <input
            type="text"
            className="form-control form-control-sm border border-dark"
            value={props.dataFiltro?.palavraChave || ''}
            onChange={(e) =>
              props.setDataFiltro({
                ...props.dataFiltro,
                palavraChave: e.target.value.toUpperCase()
              })
            }
            style={{ textTransform: 'uppercase' }}
          />
        </div>
      </div>

      {/* button */}
      <div className="row mt-2">
        <div className="col-12 d-flex align-items-center justify-content-end">
          <button
            className="btn btn-primary"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => props.setFilter(props.filter + 1)}
          >
            <i className="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
      </div>
    </>
  );
};
