import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

export const GraficoEvolucaoNotaAuditoria = ({
  data,
  seq,
  mesIni,
  anoIni,
  mesFim,
  anoFim
}) => {
  if (
    !data ||
    data.length !== 2 ||
    !data[1] ||
    data[1].length === 0 ||
    !data[0] ||
    data[0].length === 0
  ) {
    return (
      <div
        style={{
          height: '90%',
          width: '100%',
          minWidth: '600px',
          minHeight: '400px'
        }}
      >
        <h3 style={{ textAlign: 'center', color: '#000' }}>
          Nota do Ambiente/Media da Empresa
        </h3>
        <h4 style={{ textAlign: 'center', color: '#000' }}>
          Não existem dados suficientes para gerar o gráfico.
        </h4>
      </div>
    );
  }

  let [dadosGrafico, mediaArray] = data;
  if (!dadosGrafico || dadosGrafico.length === 0) {
    return <p>Não existe data suficiente.</p>;
  }
  const descricao = dadosGrafico[0].que_descri;
  const formatDate = (dateString) => {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const options = { year: 'numeric', month: 'numeric' };
    return new Date(year, month - 1).toLocaleDateString('pt-br', options);
  };

  const findMedia = (anomes) => {
    const mediaObj = dadosGrafico.find((item) => item.anomes === anomes);
    return mediaObj ? mediaObj.media_quest_mes : null;
  };

  const dataInfo = mediaArray.map((item) => ({
    Data: formatDate(item.anomes),
    'Media da Empresa': item.media_empresa_dia,
    Meta: parseInt(localStorage.getItem('sessionMeta')),
    [descricao]: findMedia(item.anomes)
  }));

  const chartData = {
    labels: dataInfo.map((item) => item.Data),
    datasets: [
      {
        type: 'line',
        label: 'Meta',
        data: dataInfo.map((item) => item.Meta),
        borderColor: '#33cc33', // verde
        backgroundColor: '#33cc33', // verde
        borderWidth: 2,
        fill: false
      },
      {
        type: 'bar',
        label: 'Media da Empresa',
        data: dataInfo.map((item) => item['Media da Empresa']),
        backgroundColor: '#ffcc00' // amarelo
      },
      {
        type: 'bar',
        label: descricao,
        data: dataInfo.map((item) => item[descricao]),
        backgroundColor: dataInfo.map(
          (item) => (item[descricao] < item.Meta ? '#ff3300' : '#3333cc') // vermelho ou azul
        )
      }
    ]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'right',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle'
        }
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.dataset.label}: ${context.raw.toFixed(2)}`;
          }
        }
      }
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Data da Aplicacao'
        }
      },
      y: {
        title: {
          display: true,
          text: 'Nota do Ambiente'
        }
      }
    }
  };

  return (
    <div
      style={{
        height: '90%',
        width: '100%',
        minWidth: '600px',
        minHeight: '400px'
      }}
    >
      <h3 style={{ textAlign: 'center', color: '#000' }}>
        Nota do Ambiente/Media da Empresa
      </h3>
      <h4 style={{ textAlign: 'center', color: '#000' }}>
        Ambiente: {descricao} | Período {mesIni}/{anoIni} - {mesFim}/{anoFim}
      </h4>
      <Bar data={chartData} options={options} />
    </div>
  );
};
