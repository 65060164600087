import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Config from '../modal/Config';
// import Login from '../modal/Login';
import i18n from '../services/i18n/i18n';

function Header(props) {
  const { t } = useTranslation();

  const [bgNavbar, setBgNavbar] = useState('#0d6efd');
  const [fontNavbar, setFontNavbar] = useState('#ffffff');

  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const [usuario, setUsuario] = useState(
    localStorage.getItem('sessionToken')
      ? localStorage.getItem('sessionNome')
      : ''
  );

  const handleLogout = () => {
    if (localStorage.getItem('sessionToken')) {
      localStorage.removeItem('sessionToken');
      window.location.reload();
    } else {
      window.location.reload();
    }
  };

  const srcImg = () => {
    switch (selectedLanguage) {
      case 'ptBR':
        return 'imagens/brasil.png';
      case 'en':
        return 'imagens/usa.jpg';
      default:
        return 'imagens/espanha.png';
    }
  };

  useEffect(() => {
    const savedConfigString = localStorage.getItem('config');
    if (savedConfigString) {
      const savedConfig = JSON.parse(savedConfigString);
      setBgNavbar(savedConfig.bgNavbar);
      setFontNavbar(savedConfig.fontNavbar);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <nav
      className="navbar navbar-expand-md"
      style={{ backgroundColor: bgNavbar }}
    >
      <div className="container">
        <div className="d-flex align-items-center">
          <img src="imagens/logo.png" alt="logo SALT" height="30" />
          <p
            className="navbar-brand ms-3 navbar-center"
            style={{ color: fontNavbar, fontSize: props.tamTitulo }}
          >
            SIE AUDITORIA {props.area}
          </p>

          {props.login && (
            <ul className="navbar-nav">
              <li
                className="nav-item ms-3 mt-1 navbar-center"
                style={{ color: fontNavbar, fontSize: props.tamTexto }}
              >
                Empresa: {props.dataEmpresa.nomorg} {t('Usuario')}: {usuario}
              </li>
            </ul>
          )}
        </div>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarMenu"
          aria-controls="navbarMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarMenu">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <div className="dropdown navbar-center">
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {t('Periodos')}
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <li>
                    <a
                      className="dropdown-item"
                      href="##"
                      onClick={() => props.handlePeriodClick('esta-semana')}
                    >
                      {t('EssaSemana')}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="##"
                      onClick={() => props.handlePeriodClick('semana-passada')}
                    >
                      {t('SemanaPassada')}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="##"
                      onClick={() => props.handlePeriodClick('proxima-semana')}
                    >
                      {t('ProximaSemana')}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="##"
                      onClick={() => props.handlePeriodClick('este-mes')}
                    >
                      {t('EsseMes')}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="##"
                      onClick={() => props.handlePeriodClick('mes-passado')}
                    >
                      {t('MesPassado')}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="##"
                      onClick={() => props.handlePeriodClick('proximo-mes')}
                    >
                      {t('ProximoMes')}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="##"
                      onClick={() => props.handlePeriodClick('este-ano')}
                    >
                      {t('EsseAno')}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="##"
                      onClick={() => props.handlePeriodClick('ano-passado')}
                    >
                      {t('AnoPassado')}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="##"
                      onClick={() => props.handlePeriodClick('proximo-ano')}
                    >
                      {t('ProximoAno')}
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <p
                className="nav-link navbar-center"
                style={{ color: fontNavbar, fontSize: props.tamTexto }}
              >
                {t(props.selectedPeriod)}
              </p>
            </li>
            {/* <li className="nav-item">
              <p
                className="nav-link navbar-center"
                data-bs-toggle="modal"
                data-bs-target="#Login"
                style={{ color: fontNavbar, fontSize: props.tamTexto }}
              >
                {t('Login')}
              </p>
              <Login
                setUsuario={setUsuario}
                usuario={usuario}
                setLogin={props.setLogin}
                tamInput={props.tamInput}
                tamTitulo={props.tamTitulo}
                tamTexto={props.tamTexto}
                dataBanco={props.dataBanco}
                setDataBanco={props.setDataBanco}
              />
            </li>
            <li className="nav-item">
              <p
                className="nav-link navbar-center"
                style={{ color: fontNavbar, fontSize: props.tamTexto }}
              >
                {t('Ajuda')}
              </p>
            </li> */}
            <li className="nav-itemr">
              <p
                className="nav-link navbar-center"
                style={{ color: fontNavbar, fontSize: props.tamTexto }}
                onClick={handleLogout}
              >
                Sair
              </p>
            </li>
            <li className="nav-item">
              <i
                className="fa fa-solid fa-gear nav-link navbar-center"
                data-bs-toggle="modal"
                data-bs-target="#Config"
                style={{ color: fontNavbar }}
              ></i>
              <Config
                Templates={props.Templates}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                bgNavbar={bgNavbar}
                setBgNavbar={setBgNavbar}
                fontNavbar={fontNavbar}
                setFontNavbar={setFontNavbar}
                bgDashboard1={props.bgDashboard1}
                setBgDashboard1={props.setBgDashboard1}
                fontDashboard1={props.fontDashboard1}
                setFontDashboard1={props.setFontDashboard1}
                bgDashboard2={props.bgDashboard2}
                setBgDashboard2={props.setBgDashboard2}
                fontDashboard2={props.fontDashboard2}
                setFontDashboard2={props.setFontDashboard2}
                bgDashboard3={props.bgDashboard3}
                setBgDashboard3={props.setBgDashboard3}
                fontDashboard3={props.fontDashboard3}
                setFontDashboard3={props.setFontDashboard3}
                bgMenu={props.bgMenu}
                setBgMenu={props.setBgMenu}
                fontMenu={props.fontMenu}
                setFontMenu={props.setFontMenu}
                login={props.login}
                tamInput={props.tamInput}
                setTamInput={props.setTamInput}
                tamTitulo={props.tamTitulo}
                setTamTitulo={props.setTamTitulo}
                tamSubTitulo={props.tamSubTitulo}
                setTamSubTitulo={props.setTamSubTitulo}
                tamTexto={props.tamTexto}
                setTamTexto={props.setTamTexto}
                tamSubTexto={props.tamSubTexto}
                setTamSubTexto={props.setTamSubTexto}
                changeState={props.changeState}
                setChangeState={props.setChangeState}
                area={props.area}
                setArea={props.setArea}
                campoArea={props.campoArea}
                setCampoArea={props.setCampoArea}
                area_verificar={props.area_verificar}
                abr_area={props.abr_area}
                dataBanco={props.dataBanco}
                setDataBanco={props.setDataBanco}
              />
            </li>
            <li className="nav-item">
              <img src={srcImg()} className="mt-2 img-nav" alt="Logo Brasil" />
            </li>
            <li className="nav-item">
              <p
                className="nav-link navbar-center"
                style={{ color: fontNavbar, fontSize: '0.8em' }}
              >
                V 1.01.87
              </p>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Header;
