import {
  handleFirstPage,
  handleLastPage,
  handlePageClick,
  handleRowClick,
  questionarioAreaPerguntaDelete
} from '../../functions';

const BlcTitle = (props) => {
  return (
    <th scope="col">
      <div className="d-flex justify-content-between align-items-center">
        <span style={{ fontSize: props.tamSubTexto }}>{props.title}</span>
      </div>
    </th>
  );
};

export const GridPerguntaQuestionario = (props) => {
  const campos = [
    { title: 'Desc. Subarea' },
    { title: 'Desc. da Pergunta' },
    { title: 'Cód. do Ambiente (Quest.)' },
    { title: 'Desc. do Ambiente (Quest.)' }
  ];

  return (
    <div>
      <p className="fs-6">Pergunta: {props.perguntaSelecionada}</p>

      <table
        className="table table-striped table-hover table-bordered table-sm align-middle"
        style={{ height: '100%' }}
      >
        <thead>
          <tr className="table-secondary">
            {campos.map((campo, index) => (
              <BlcTitle
                key={index}
                title={campo.title}
                tamTexto={props.tamSubTexto}
              />
            ))}
            {/* {props.insertOrUpdate !== 'read' &&
            (localStorage.getItem('sessionNivelDeAcesso') === '22' ||
            localStorage.getItem('sessionNivelDeAcesso') === '50' ||
            localStorage.getItem('sessionNivelDeAcesso') === '99' ? (
              <th
              scope="col"
              style={{ fontSize: props.tamTexto, width: '80px' }}
              >
              Deletar
              </th>
              ) : null)} */}
          </tr>
        </thead>
        <tbody>
          {props.filteredData?.map((item, index) => (
            <tr
              key={index}
              className={props.selectedRow === index ? 'bg-info' : ''}
              onClick={() => handleRowClick(index, props.setSelectedRow)}
            >
              <td style={{ fontSize: props.tamSubTexto }}>
                {props.data.subarea}
              </td>
              <td style={{ fontSize: props.tamSubTexto }}>
                {props.data.pergunta}
              </td>
              <td style={{ fontSize: props.tamSubTexto }}>{item.qap_seqque}</td>
              <td style={{ fontSize: props.tamSubTexto }}>{item.que_descri}</td>
              {/* {props.insertOrUpdate !== 'read' &&
              (localStorage.getItem('sessionNivelDeAcesso') === '22' ||
              localStorage.getItem('sessionNivelDeAcesso') === '50' ||
              localStorage.getItem('sessionNivelDeAcesso') === '99' ? (
                <td>
                <div>
                <button
                      type="button"
                      className="btn btn-outline-danger btn-sm"
                      onClick={async () => {
                        const body = {
                          id: item.qap_seqque,
                          area: item.qap_codare,
                          subarea: item.qap_codsar,
                          pergunta: item.qap_codper
                          };
                        const response = await questionarioAreaPerguntaDelete(
                          body
                          );
                        await props.handlePerguntaExistentes();
                        }}
                        style={{ fontSize: props.tamSubTexto }}
                        >
                        <i className="fa-solid fa-trash"></i>
                        </button>
                        </div>
                        </td>
                        ) : null)} */}
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="7" className="align-middle">
              <nav className="d-flex align-items-center justify-content-center">
                <ul className="pagination">
                  <li className="page-item">
                    <p
                      className="page-link"
                      aria-label="PreviousPage"
                      onClick={() =>
                        handleFirstPage(
                          props.setCurrentPage,
                          props.setSelectedRow
                        )
                      }
                    >
                      <span
                        aria-hidden="true"
                        style={{ fontSize: props.tamTexto }}
                      >
                        &laquo;
                      </span>
                    </p>
                  </li>
                  <li className="page-item">
                    <p
                      className="page-link"
                      aria-label="PreviousIten"
                      onClick={() =>
                        handlePageClick(
                          props.currentPage - 1,
                          props.pageCount,
                          props.setCurrentPage,
                          props.setSelectedRow
                        )
                      }
                    >
                      <span
                        aria-hidden="true"
                        style={{ fontSize: props.tamTexto }}
                      >
                        &lt;
                      </span>
                    </p>
                  </li>
                  {props.displayedPageNumbers.map((pageNumber) => (
                    <li
                      key={pageNumber}
                      className={`page-item ${
                        pageNumber === props.currentPage ? 'active' : ''
                      }`}
                      style={{ fontSize: props.tamTexto }}
                    >
                      <button
                        className="page-link"
                        onClick={() =>
                          handlePageClick(
                            pageNumber,
                            props.pageCount,
                            props.setCurrentPage,
                            props.setSelectedRow
                          )
                        }
                      >
                        {pageNumber + 1}
                      </button>
                    </li>
                  ))}
                  <li className="page-item">
                    <p
                      className="page-link"
                      aria-label="NextIten"
                      onClick={() =>
                        handlePageClick(
                          props.currentPage + 1,
                          props.pageCount,
                          props.setCurrentPage,
                          props.setSelectedRow
                        )
                      }
                    >
                      <span
                        aria-hidden="true"
                        style={{ fontSize: props.tamTexto }}
                      >
                        &gt;
                      </span>
                    </p>
                  </li>
                  <li className="page-item">
                    <p
                      className="page-link"
                      aria-label="NextPage"
                      onClick={() =>
                        handleLastPage(
                          props.setCurrentPage,
                          props.setSelectedRow,
                          props.pageCount
                        )
                      }
                    >
                      <span
                        aria-hidden="true"
                        style={{ fontSize: props.tamTexto }}
                      >
                        &raquo;
                      </span>
                    </p>
                  </li>
                </ul>
                <div className="d-flex flex-column">
                  <p
                    className="text-muted ms-2"
                    style={{ fontSize: props.tamTexto }}
                  >
                    Quantidade de Registros: {props.contAmbienteExistentes}
                  </p>
                </div>
              </nav>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
