import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

export const GraficoBarraSubArea = ({
  data,
  metaSubArea,
  mesIni,
  anoIni,
  mesFim,
  anoFim,
  titulo
}) => {
  if (!data || data.length === 0) {
    return (
      <div
        style={{
          height: '90%',
          width: '100%',
          minWidth: '600px',
          minHeight: '400px'
        }}
      >
        <h3 style={{ textAlign: 'center', color: '#000' }}>{titulo}</h3>
        <h4 style={{ textAlign: 'center', color: '#000' }}>
          Não existem dados suficientes para gerar o gráfico.
        </h4>
      </div>
    );
  }

  const formatDate = (dateString) => {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const options = { year: 'numeric', month: 'numeric' };
    return new Date(year, month - 1).toLocaleDateString('pt-br', options);
  };

  const dataInfo = data.map((item) => ({
    id: item.sequencial_descricao,
    data: formatDate(item.data_id),
    y: item.media,
    meta: metaSubArea[item.sequencial_descricao]
  }));

  let dataMap = {};
  let transformedData = [];

  dataInfo.forEach((item) => {
    let idParts = item.id.split(' - ');
    if (!dataMap[item.data]) {
      let newItem = {
        data: item.data,
        [item.id]: item.y,
        ['Meta - ' + idParts[1]]: item.meta
      };
      dataMap[item.data] = newItem;
      transformedData.push(newItem);
    } else {
      dataMap[item.data][item.id] = item.y;
      dataMap[item.data]['Meta - ' + idParts[1]] = item.meta;
    }
  });

  const colors = {
    'Meta - ORGANIZAÇÃO': '#33cc33',
    '1 S - ORGANIZAÇÃO': '#cc33cc', // darker purple
    'Meta - PADRONIZAÇÃO': '#33cc33',
    '4 S - PADRONIZAÇÃO': '#9933cc', // darker violet
    'Meta - ARRUMAÇÃO': '#33cc33',
    '2 S - ARRUMAÇÃO': '#6666cc', // darker blue
    'Meta - LIMPEZA': '#33cc33',
    '3 S - LIMPEZA': '#3366cc', // darker blue
    'Meta - DISCIPLINA': '#33cc33',
    '5 S - DISCIPLINA': '#003399', // darker blue,
    Meta: '#33cc33'
  };

  const chartData = {
    labels: transformedData.map((item) => item.data),
    datasets: Object.keys(colors).map((key) => ({
      type: key.startsWith('Meta') ? 'line' : 'bar',
      label: key,
      data: transformedData.map((item) => item[key]),
      backgroundColor: colors[key],
      borderColor: colors[key],
      borderWidth: key.startsWith('Meta') ? 4 : 0, // Increase border width for Meta lines
      pointStyle: key.startsWith('Meta') ? 'circle' : undefined,
      pointRadius: key.startsWith('Meta') ? 6 : undefined,
      fill: key.startsWith('Meta') ? false : true
    }))
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'right',
        labels: {
          filter: function (legendItem, chartData) {
            return !legendItem.text.startsWith('Meta -');
          },
          usePointStyle: true,
          pointStyle: 'circle'
        }
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.dataset.label}: ${context.raw.toFixed(2)}`;
          }
        }
      }
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Data da Aplicacao'
        }
      },
      y: {
        title: {
          display: true,
          text: 'Nota do Ambiente'
        }
      }
    }
  };

  return (
    <div
      style={{
        height: '90%',
        width: '100%',
        minWidth: '600px',
        minHeight: '400px'
      }}
    >
      <h3 style={{ textAlign: 'center', color: '#000' }}>{titulo}</h3>
      <h4 style={{ textAlign: 'center', color: '#000' }}>
        Período {mesIni}/{anoIni} - {mesFim}/{anoFim}
      </h4>
      <Bar data={chartData} options={options} />
    </div>
  );
};
