import { colorSchemes } from '@nivo/colors';
import { ResponsiveLine } from '@nivo/line';

export const Modal2GLinha = ({
  data,
  seq,
  mesIni,
  anoIni,
  mesFim,
  anoFim,
  titulo
}) => {
  if (!data || data.length === 0) {
    return (
      <div
        style={{
          height: '90%',
          width: '100%',
          minWidth: '600px',
          minHeight: '400px'
        }}
      >
        <h3 style={{ textAlign: 'center', color: '#000' }}>{titulo}</h3>
        <h4 style={{ textAlign: 'center', color: '#000' }}>
          Não existem dados suficientes para gerar o gráfico.
        </h4>
      </div>
    );
  }
  let dadosGrafico = data;
  let media = parseInt(localStorage.getItem('sessionMeta'));
  const formatDate = (dateString) => {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const options = { year: 'numeric', month: 'numeric' };
    return new Date(year, month - 1).toLocaleDateString('pt-br', options);
  };
  const dataMap = data.reduce((map, item) => {
    if (!map[item.sequencial_descricao]) {
      map[item.sequencial_descricao] = [];
    }
    map[item.sequencial_descricao].push({
      x: formatDate(item.data_id),
      y: item.media,
      descricao: item.sequencial_descricao
    });
    return map;
  }, {});

  const dataInfo = Object.entries(dataMap).map(
    ([sequencial_id, dataPoints]) => ({
      id: sequencial_id,
      data: dataPoints
    })
  );

  if (!dataInfo.find((item) => item.id === 'Meta')) {
    dataInfo.unshift({
      id: 'Meta',
      color: 'green',
      data: dadosGrafico.map((item) => ({
        x: formatDate(item.data_id),
        y: media
      }))
    });
  }

  return (
    <div
      style={{
        height: '90%',
        width: '100%',
        minWidth: '600px',
        minHeight: '400px'
      }}
    >
      <h3 style={{ textAlign: 'center', color: '#000' }}>{titulo}</h3>
      <h4 style={{ textAlign: 'center', color: '#000' }}>
        Período {mesIni}/{anoIni} - {mesFim}/{anoFim}
      </h4>
      <ResponsiveLine
        data={dataInfo}
        colors={(d) => {
          const index = dataInfo.findIndex((item) => item.id === d.id);
          return d.id === 'Meta'
            ? d.color
            : colorSchemes.nivo[index % colorSchemes.nivo.length];
        }}
        crosshairType="cross"
        margin={{ top: 50, right: 185, bottom: 50, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: false,
          reverse: false
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Data da Aplicacao',
          legendOffset: 36,
          legendPosition: 'middle',
          format: (value) => {
            const year = value.slice(0, 4);
            const month = value.slice(4);
            return `${value}`;
          }
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Nota do Ambiente',
          legendOffset: -55,
          legendPosition: 'middle',
          format: (value) => {
            return parseFloat(value).toLocaleString('pt-BR', {
              style: 'decimal',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            });
          }
        }}
        pointSize={8}
        pointColor={{ from: 'color', modifiers: [] }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor', modifiers: [] }}
        enablePointLabel={true}
        enableSlices="x"
        sliceTooltip={({ slice }) => {
          return (
            <div
              style={{
                background: 'white',
                padding: '9px 12px',
                border: '1px solid #ccc'
              }}
            >
              <h3>{slice.points[0].data.x}</h3>
              {slice.points
                .filter(
                  (v, i, a) => a.findIndex((t) => t.serieId === v.serieId) === i
                )
                .map((point) => (
                  <div key={point.id}>
                    <strong style={{ color: point.serieColor }}>
                      {point.serieId}
                    </strong>{' '}
                    {point.data.yFormatted}
                  </div>
                ))}
            </div>
          );
        }}
        pointLabelYOffset={-12}
        lineWidth={3}
        useMesh={true}
        yFormat={(value) => `${value.toFixed(2)}`}
        pointLabel="yFormatted"
        legends={[
          {
            anchor: 'right',
            direction: 'column',
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
      />
    </div>
  );
};
