export const CadGeral = ({ data, setData, insertOrUpdate, optionsArea }) => {
  return (
    <div className="row">
      <div className="col-12">
        <div className="col-12 row">
          <div className="col-3 col-md-2">
            <span className="col-form-label">Cód. da Área</span>
          </div>
          <div className="col-9 col-md-10">
            <span className="col-form-label">Descrição da Área</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3 col-md-2">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              value={data?.codigoArea || ''}
              disabled
            />
          </div>
          <div className="col-9 col-md-10">
            <select
              className="form-select form-select-sm border border-dark"
              name="res_tipapa"
              value={data?.codigoArea || ''}
              onChange={(e) => setData({ ...data, codigoArea: e.target.value })}
              disabled={insertOrUpdate === 'read' && true}
            >
              <option defaultValue></option>
              {optionsArea
                ?.slice()
                .sort((a, b) => a.are_descri.localeCompare(b.are_descri))
                .map((option, index) => (
                  <option key={index} value={option.are_codare}>
                    {option.are_descri}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-3 col-md-2">
            <span className="col-form-label">Cód. da Subarea</span>
          </div>
          <div className="col-9 col-md-10">
            <span className="col-form-label">Descrição da Subarea</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-3 col-md-2">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              value={data?.id || ''}
              disabled
            />
          </div>
          <div className="col-9 col-md-10">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              value={data?.descricao || ''}
              onChange={(e) => setData({ ...data, descricao: e.target.value })}
              disabled={insertOrUpdate === 'read' && true}
              style={{ textTransform: 'uppercase' }}
            />
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Abreviatura</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              value={data?.abreviacao || ''}
              onChange={(e) => setData({ ...data, abreviacao: e.target.value })}
              disabled={insertOrUpdate === 'read' && true}
              style={{ textTransform: 'uppercase' }}
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-6 col-md-3">
            <span className="col-form-label">Peso</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6 col-md-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              value={data?.peso || ''}
              onChange={(e) => setData({ ...data, peso: e.target.value })}
              disabled={insertOrUpdate === 'read' && true}
            />
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="col-12 row">
          <div className="col-6 col-md-3">
            <span className="col-form-label">Usuário</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6 col-md-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              value={data?.usuario || ''}
              disabled
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-6 col-md-3">
            <span className="col-form-label">Dt. Inclusão</span>
          </div>
          <div className="col-6 col-md-3">
            <span className="col-form-label">Hr. Inclusão</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6 col-md-3">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              value={data?.datincl || ''}
              disabled
            />
          </div>
          <div className="col-6 col-md-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              value={data?.horincl || ''}
              disabled
            />
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-6 col-md-3">
            <span className="col-form-label">Dt. Atualização</span>
          </div>
          <div className="col-6 col-md-3">
            <span className="col-form-label">Hr. Atualização</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-6 col-md-3">
            <input
              type="date"
              className="form-control form-control-sm border border-dark"
              value={data?.datatz || ''}
              disabled
            />
          </div>
          <div className="col-6 col-md-3">
            <input
              type="text"
              className="form-control form-control-sm border border-dark"
              value={data?.horatz || ''}
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  );
};
