import {
  handleFirstPage,
  handleLastPage,
  handlePageClick,
  handleRowClick
} from '../../functions';

const BlcTitle = (props) => {
  return (
    <th scope="col">
      <div className="d-flex justify-content-between align-items-center">
        <span style={{ fontSize: props.tamSubTexto }}>{props.title}</span>
      </div>
    </th>
  );
};

export const GridAmbienteNotPergunta = (props) => {
  const campos = [
    { title: 'Cód. do Ambiente (Quest.)' },
    { title: 'Descrição do Ambiente (Quest.)' }
  ];

  return (
    <div>
      <p className="fs-6">Pergunta: {props.perguntaSelecionada}</p>

      <table
        className="table table-striped table-hover table-bordered table-sm align-middle"
        style={{ height: '100%' }}
      >
        <thead>
          <tr className="table-secondary">
            {campos.map((campo, index) => (
              <BlcTitle
                key={index}
                title={campo.title}
                tamTexto={props.tamSubTexto}
              />
            ))}
            <th scope="col" style={{ fontSize: props.tamTexto, width: '80px' }}>
              Adicionar
            </th>
          </tr>
        </thead>
        <tbody>
          {props.filteredData?.map((item, index) => (
            <tr
              key={index}
              className={props.selectedRow === index ? 'bg-info' : ''}
              onClick={() => handleRowClick(index, props.setSelectedRow)}
            >
              <td scope="row" style={{ fontSize: props.tamSubTexto }}>
                {item.que_seqque}
              </td>
              <td style={{ fontSize: props.tamSubTexto }}>{item.que_descri}</td>
              <td>
                <div>
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-sm"
                    onClick={async () => {
                      const body = {
                        id: item.que_seqque,
                        area: props.data.codigoArea,
                        subarea: props.data.codigoSubarea,
                        pergunta: props.data.codigoPergunta,
                        usuario: localStorage.getItem('sessionUsuario'),
                        ordemArea: props.data.ordemArea,
                        ordemSubarea: props.data.ordemSubarea,
                        ordemPergunta: props.data.ordemPergunta,
                        questionarioDescricao: item.que_descri
                      };

                      await props.handleSave(body);
                    }}
                    style={{ fontSize: props.tamSubTexto }}
                  >
                    <i className="fa-solid fa-plus"></i>
                  </button>
                </div>

                {/* <div>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={props.data.ambientes?.includes(item.que_seqque)}
                    onChange={() => {
                      const updatedAmbientes = props.data.ambientes?.includes(
                        item.que_seqque
                      )
                        ? props.data.ambientes.filter(
                            (id) => id !== item.que_seqque
                          )
                        : [...(props.data.ambientes || ''), item.que_seqque];

                      const updatedAmbientesDescricao =
                        props.data.ambientes?.includes(item.que_descri)
                          ? props.data.ambientesDescricao.filter(
                              (id) => id !== item.que_descri
                            )
                          : [
                              ...(props.data.ambientesDescricao || ''),
                              item.que_descri
                            ];

                      props.setData({
                        ...props.data,
                        ambientes: updatedAmbientes,
                        ambientesDescricao: updatedAmbientesDescricao
                      });
                    }}
                  />
                </div> */}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="4" className="align-middle">
              <nav className="d-flex align-items-center justify-content-center">
                <ul className="pagination">
                  <li className="page-item">
                    <p
                      className="page-link"
                      aria-label="PreviousPage"
                      onClick={() =>
                        handleFirstPage(
                          props.setCurrentPage,
                          props.setSelectedRow
                        )
                      }
                    >
                      <span
                        aria-hidden="true"
                        style={{ fontSize: props.tamTexto }}
                      >
                        &laquo;
                      </span>
                    </p>
                  </li>
                  <li className="page-item">
                    <p
                      className="page-link"
                      aria-label="PreviousIten"
                      onClick={() =>
                        handlePageClick(
                          props.currentPage - 1,
                          props.pageCount,
                          props.setCurrentPage,
                          props.setSelectedRow
                        )
                      }
                    >
                      <span
                        aria-hidden="true"
                        style={{ fontSize: props.tamTexto }}
                      >
                        &lt;
                      </span>
                    </p>
                  </li>
                  {props.displayedPageNumbers.map((pageNumber) => (
                    <li
                      key={pageNumber}
                      className={`page-item ${
                        pageNumber === props.currentPage ? 'active' : ''
                      }`}
                      style={{ fontSize: props.tamTexto }}
                    >
                      <button
                        className="page-link"
                        onClick={() =>
                          handlePageClick(
                            pageNumber,
                            props.pageCount,
                            props.setCurrentPage,
                            props.setSelectedRow
                          )
                        }
                      >
                        {pageNumber + 1}
                      </button>
                    </li>
                  ))}
                  <li className="page-item">
                    <p
                      className="page-link"
                      aria-label="NextIten"
                      onClick={() =>
                        handlePageClick(
                          props.currentPage + 1,
                          props.pageCount,
                          props.setCurrentPage,
                          props.setSelectedRow
                        )
                      }
                    >
                      <span
                        aria-hidden="true"
                        style={{ fontSize: props.tamTexto }}
                      >
                        &gt;
                      </span>
                    </p>
                  </li>
                  <li className="page-item">
                    <p
                      className="page-link"
                      aria-label="NextPage"
                      onClick={() =>
                        handleLastPage(
                          props.setCurrentPage,
                          props.setSelectedRow,
                          props.pageCount
                        )
                      }
                    >
                      <span
                        aria-hidden="true"
                        style={{ fontSize: props.tamTexto }}
                      >
                        &raquo;
                      </span>
                    </p>
                  </li>
                </ul>
                <div className="d-flex flex-column">
                  <p
                    className="text-muted ms-2"
                    style={{ fontSize: props.tamTexto }}
                  >
                    Quantidade de Registros: {props.contAmbienteNotPergunta}
                  </p>
                </div>
              </nav>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
