import { useCallback, useEffect, useState } from 'react';

import { ApiPostNew } from '../services/apiService';
import ChartContainer from './graficos/ChartContainer';
import { ChartLine } from './graficos/ChartLine';
// import { GraficoBarra } from './graficos/GraficoBarra';

export const Modal5 = ({ OpcoesSequencial }) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  const pastDate = new Date();
  pastDate.setMonth(currentDate.getMonth() - 3);
  const pastYear = pastDate.getFullYear();
  const pastMonth = pastDate.getMonth() + 1;

  const formatDate = (year, month) => {
    return `${year}${month.toString().padStart(2, '0')}`;
  };

  const initialDataInicial = formatDate(pastYear, pastMonth);
  const initialDataFinal = formatDate(currentYear, currentMonth);
  const [seq, setSeq] = useState();
  const [dataInicial, setDataInicial] = useState(initialDataInicial);
  const [dataFinal, setDataFinal] = useState(initialDataFinal);
  const [graficoLinhaData, setGraficoLinhaData] = useState([]);

  const prevSeqOptions = OpcoesSequencial.slice().sort((a, b) =>
    a.que_descri.localeCompare(b.que_descri)
  );

  const seqOptions = prevSeqOptions.map((option, index) => {
    return { item: `${option.que_seqque} - ${option.que_descri}` };
  });

  const handleSearch = useCallback(async () => {
    try {
      let useSeq;
      if (!seq) {
        useSeq = prevSeqOptions[0]?.que_seqque;
      } else {
        useSeq = seq.value || seq;
      }
      const response = await ApiPostNew(
        '/auditoria/graficonotageral/',
        {
          que_seqque: useSeq,
          dateIni: dataInicial,
          dateFim: dataFinal
        },
        localStorage.getItem('sessionToken')
      );
      return response.data?.auditoria || [];
    } catch (err) {
      console.log(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seq, dataInicial, dataFinal]);

  useEffect(() => {
    handleSearch().then((data) => {
      setGraficoLinhaData(data);
    });
  }, [handleSearch]);

  return (
    <>
      <div
        className="modal fade modal-xl"
        id="ModalGrafico5"
        tabIndex="-1"
        aria-labelledby="lblGrafico1"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-scrollable modalDialog">
          <div className="modal-content">
            <div className="modal-header" style={{ height: '3rem' }}>
              <div className="d-flex justify-content-botween align-items-center w-100">
                <i
                  className="fa-solid fa-arrow-left fs-5"
                  data-bs-toggle="modal"
                  data-bs-target="#ModalLista"
                ></i>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
            </div>

            <ChartContainer
              seqOptions={seqOptions}
              setSeq={setSeq}
              dataInicialFormatted={String(dataInicial)}
              dataFinalFormatted={String(dataFinal)}
              setDataInicial={setDataInicial}
              setDataFinal={setDataFinal}
            >
              <ChartLine
                data={graficoLinhaData}
                seq={seq}
                mesIni={String(dataInicial).slice(4, 6)}
                anoIni={String(dataInicial).slice(0, 4)}
                mesFim={String(dataFinal).slice(4, 6)}
                anoFim={String(dataFinal).slice(0, 4)}
              />
            </ChartContainer>
          </div>
        </div>
      </div>
    </>
  );
};
