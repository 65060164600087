import { useEffect, useRef, useState } from 'react';

import { ApiGet } from '../../services/apiService';
import {
  ambienteSelect,
  areaSelect,
  getDisplayedPageNumbers,
  questionarioAreaPerguntaCopia,
  questionarioAreaPerguntaPost,
  questionarioAreaPerguntaPostVarios,
  questionarioAreaPerguntaSelectUnique,
  subareaSelect
} from './functions';
import { Cadastro } from './questionarioAreaPergunta/cadastro/Cadastro';
import { Grid } from './questionarioAreaPergunta/Grid';
import { GridQuestionarioArea } from './questionarioAreaPergunta/GridQuestionarioArea';

import Swal from 'sweetalert2';

export const QuestionarioAreaPergunta = (props) => {
  const prevPerguntasRef = useRef();
  const prevAbreviaturaRef = useRef();
  const prevDescricaoRef = useRef();
  const [activeTabNavegacao, setActiveTabNavegacao] = useState(1);
  const [activeTabQuesPer, setActiveTabQuesPer] = useState(1);
  const [activeTab, setActiveTab] = useState(1);
  const [activeTabCadastro, setActiveTabCadastro] = useState(1);
  const [dataGrid, setDataGrid] = useState([]);
  const [dataGridUnique, setDataGridUnique] = useState([]);
  const [insertOrUpdate, setInsertOrUpdate] = useState(null);
  const [data, setData] = useState({});
  const [isQuestionarioArea, setIsQuestionarioArea] = useState(false);
  const [optionsArea, setOptionsArea] = useState([]);
  const [optionsSubarea, setOptionsSubarea] = useState([]);
  const [optionsQuestionario, setOptionsQuestionario] = useState([]);
  const [optionsQuestionarioNovo, setOptionsQuestionarioNovo] = useState([]);
  const [optionsQuestionarioNovoCopia, setOptionsQuestionarioNovoCopia] =
    useState([]);
  const [isPerguntaOrAmbiente, setIsPerguntaOrAmbiente] = useState('');
  const [perguntaSelecionada, setPerguntaSelecionada] = useState('');

  const itemsPerPage = 10;

  const [dataPergunta, setDataPergunta] = useState([]);
  const [pageCountPergunta, setPageCountPergunta] = useState(0);
  const [currentPagePergunta, setCurrentPagePergunta] = useState(0);
  const [selectedRowPergunta, setSelectedRowPergunta] = useState(null);
  const [filteredDataPergunta, setFilteredDataPergunta] = useState([]);
  const [contPergunta, setContPergunta] = useState([]);
  const offsetPergunta = currentPagePergunta * itemsPerPage;

  const [dataQuestionarioArea, setDataQuestionarioArea] = useState([]);
  const [pageCountQuestionarioArea, setPageCountQuestionarioArea] = useState(0);
  const [currentPageQuestionarioArea, setCurrentPageQuestionarioArea] =
    useState(0);
  const [selectedRowQuestionarioArea, setSelectedRowQuestionarioArea] =
    useState(null);
  const [filteredDataQuestionarioArea, setFilteredDataQuestionarioArea] =
    useState([]);
  const [contQuestionarioArea, setContQuestionarioArea] = useState([]);
  const offsetQuestionarioArea = currentPageQuestionarioArea * itemsPerPage;

  const [dataQuestionarioAreaDados, setDataQuestionarioAreaDados] = useState(
    []
  );
  const [pageCountQuestionarioAreaDados, setPageCountQuestionarioAreaDados] =
    useState(0);
  const [
    currentPageQuestionarioAreaDados,
    setCurrentPageQuestionarioAreaDados
  ] = useState(0);
  const [
    selectedRowQuestionarioAreaDados,
    setSelectedRowQuestionarioAreaDados
  ] = useState(null);
  const [
    filteredDataQuestionarioAreaDados,
    setFilteredDataQuestionarioAreaDados
  ] = useState([]);
  const [contQuestionarioAreaDados, setContQuestionarioAreaDados] = useState(
    []
  );
  const offsetQuestionarioAreaDados =
    currentPageQuestionarioAreaDados * itemsPerPage;

  const [dataPerguntasExistentes, setDataPerguntasExistentes] = useState([]);
  const [pageCountPerguntaExistentes, setPageCountPerguntaExistentes] =
    useState(0);
  const [currentPagePerguntaExistentes, setCurrentPagePerguntaExistentes] =
    useState(0);
  const [selectedRowPerguntaExistentes, setSelectedRowPerguntaExistentes] =
    useState(null);
  const [filteredDataPerguntaExistentes, setFilteredDataPerguntaExistentes] =
    useState([]);
  const [contPerguntaExistentes, setContPerguntaExistentes] = useState([]);
  const offsetPerguntaExistentes = currentPagePerguntaExistentes * itemsPerPage;

  const [dataAmbienteExistentes, setDataAmbienteExistentes] = useState([]);
  const [pageCountAmbienteExistentes, setPageCountAmbienteExistentes] =
    useState(0);
  const [currentPageAmbienteExistentes, setCurrentPageAmbienteExistentes] =
    useState(0);
  const [selectedRowAmbienteExistentes, setSelectedRowAmbienteExistentes] =
    useState(null);
  const [filteredDataAmbienteExistentes, setFilteredDataAmbienteExistentes] =
    useState([]);
  const [contAmbienteExistentes, setContAmbienteExistentes] = useState([]);
  const offsetAmbienteExistentes = currentPageAmbienteExistentes * itemsPerPage;

  const [dataAmbienteNotPergunta, setDataAmbienteNotPergunta] = useState([]);
  const [pageCountAmbienteNotPergunta, setPageCountAmbienteNotPergunta] =
    useState(0);
  const [currentPageAmbienteNotPergunta, setCurrentPageAmbienteNotPergunta] =
    useState(0);
  const [selectedRowAmbienteNotPergunta, setSelectedRowAmbienteNotPergunta] =
    useState(null);
  const [filteredDataAmbienteNotPergunta, setFilteredDataAmbienteNotPergunta] =
    useState([]);
  const [contAmbienteNotPergunta, setContAmbienteNotPergunta] = useState([]);
  const offsetAmbienteNotPergunta =
    currentPageAmbienteNotPergunta * itemsPerPage;

  const [dataQuestionario, setDataQuestionario] = useState([]);
  const [pageCountQuestionario, setPageCountQuestionario] = useState(0);
  const [currentPageQuestionario, setCurrentPageQuestionario] = useState(0);
  const [selectedRowQuestionario, setSelectedRowQuestionario] = useState(null);
  const [filteredDataQuestionario, setFilteredDataQuestionario] = useState([]);
  const [contQuestionario, setContQuestionario] = useState([]);
  const offsetQuestionario = currentPageQuestionario * itemsPerPage;

  const [dataEscolherPergunta, setDataEscolherPergunta] = useState([]);
  const [pageCountEscolherPergunta, setPageCountEscolherPergunta] = useState(0);
  const [currentPageEscolherPergunta, setCurrentPageEscolherPergunta] =
    useState(0);
  const [selectedRowEscolherPergunta, setSelectedRowEscolherPergunta] =
    useState(null);
  const [filteredDataEscolherPergunta, setFilteredDataEscolherPergunta] =
    useState([]);
  const [contEscolherPergunta, setContEscolherPergunta] = useState([]);
  const offsetEscolherPergunta = currentPageEscolherPergunta * itemsPerPage;

  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const offset = currentPage * itemsPerPage;

  const displayedPageNumbers = getDisplayedPageNumbers(currentPage, pageCount);
  const displayedPageNumbersPergunta = getDisplayedPageNumbers(
    currentPagePergunta,
    pageCountPergunta
  );
  const displayedPageNumbersPerguntaExistentes = getDisplayedPageNumbers(
    currentPagePerguntaExistentes,
    pageCountPerguntaExistentes
  );
  const displayedPageNumbersAmbienteExistentes = getDisplayedPageNumbers(
    currentPageAmbienteExistentes,
    pageCountAmbienteExistentes
  );
  const displayedPageNumbersAmbienteNotPergunta = getDisplayedPageNumbers(
    currentPageAmbienteNotPergunta,
    pageCountAmbienteNotPergunta
  );
  const displayedPageNumbersQuestionario = getDisplayedPageNumbers(
    currentPageQuestionario,
    pageCountQuestionario
  );
  const displayedPageNumbersEscolherPergunta = getDisplayedPageNumbers(
    currentPageEscolherPergunta,
    pageCountEscolherPergunta
  );
  const displayedPageNumbersQuestionarioArea = getDisplayedPageNumbers(
    currentPageQuestionarioArea,
    pageCountQuestionarioArea
  );
  const displayedPageNumbersQuestionarioAreaDados = getDisplayedPageNumbers(
    currentPageQuestionarioAreaDados,
    pageCountQuestionarioAreaDados
  );

  const handlePerguntaSubarea = async (qap) => {
    try {
      const response = await ApiGet(
        '/v1/selectTabela/',
        {
          from: 'pergunta_subarea',
          select: [
            'psa_codare',
            'psa_codsar',
            'psa_codper',
            'per_descri',
            'per_abrevi'
          ],
          groupBy: '',
          where: {
            psa_codare: `${data?.codigoArea || ''}`,
            psa_codsar: `${data?.codigoSubarea || ''}`,
            per_descri: {
              like: `%${
                props.dataFiltro?.descricao ||
                data?.descricaoPergunta?.toUpperCase() ||
                ''
              }%`
            },
            per_abrevi: { like: `%${data?.abreviatura?.toUpperCase() || ''}%` }
          },
          order: {
            column:
              'psa_codare, psa_codsar, psa_codper, per_descri, per_abrevi',
            isAscending: true
          },
          join: ['left', 'tab_pergunta', 'psa_codper = per_codper']
        },
        localStorage.getItem('sessionToken')
      );

      const res = response.data.dados.rows;

      setDataPergunta(res);

      const filter = res.filter(
        (item) => !qap.some((option) => item.psa_codper === option.qap_codper)
      );

      setFilteredDataPergunta(
        filter.slice(offsetPergunta, offsetPergunta + itemsPerPage)
      );
      setPageCountPergunta(Math.ceil(filter.length / itemsPerPage));
      setContPergunta(filter.length);
      setCurrentPagePergunta(0);
      setSelectedRowPergunta(null);
    } catch (err) {
      console.log(err);
    }
  };

  const handlePerguntaExistentes = async () => {
    try {
      const response = await ApiGet(
        '/v1/selectTabela/',
        {
          from: 'questionario_area_pergunta',
          select: [
            'qap_codare',
            'qap_codsar',
            'qap_seqque',
            'qap_codper',
            'per_descri'
          ],
          groupBy: '',
          where: {
            qap_codare: `${data?.codigoArea || ''}`,
            qap_codsar: `${data?.codigoSubarea || ''}`,
            qap_seqque: `${data?.codigoQuestionario || ''}`
          },
          order: {
            column:
              'qap_codare, qap_codsar, qap_seqque, qap_codper, per_descri',
            isAscending: true
          },
          join: ['left', 'tab_pergunta', 'qap_codper = per_codper']
        },
        localStorage.getItem('sessionToken')
      );
      const res = response.data.dados.rows;

      setDataPerguntasExistentes(res);

      setFilteredDataPerguntaExistentes(
        res.slice(
          offsetPerguntaExistentes,
          offsetPerguntaExistentes + itemsPerPage
        )
      );
      setPageCountPerguntaExistentes(Math.ceil(res.length / itemsPerPage));
      setContPerguntaExistentes(res.length);
      setCurrentPagePerguntaExistentes(0);
      setSelectedRowPerguntaExistentes(null);

      await handlePerguntaSubarea(res);
    } catch (err) {
      console.log(err);
    }
  };

  const handleAmbienteExistentes = async (id = '') => {
    try {
      const response = await ApiGet(
        '/v1/selectTabela/',
        {
          from: 'questionario_area_pergunta',
          select: [
            'qap_codare',
            'qap_codsar',
            'qap_seqque',
            'qap_codper',
            '(SELECT que_descri from questionario where que_seqque = qap_seqque) as que_descri'
          ],
          groupBy: 'qap_seqque, que_descri, qap_codper, qap_codsar, qap_codare',
          where: {
            qap_codare: `${data?.codigoArea || ''}`,
            qap_codsar: `${data?.codigoSubarea || ''}`,
            qap_codper: `${(id !== '' && id) || data?.codigoPergunta || ''}`
          },
          order: {
            column: ' que_descri, qap_seqque ',
            isAscending: true
          },
          join: ['left', 'tab_pergunta', 'qap_codper = per_codper']
        },
        localStorage.getItem('sessionToken')
      );
      const res = response.data.dados.rows;

      setDataAmbienteExistentes(res);

      setFilteredDataAmbienteExistentes(
        res.slice(
          offsetAmbienteExistentes,
          offsetAmbienteExistentes + itemsPerPage
        )
      );
      setPageCountAmbienteExistentes(Math.ceil(res.length / itemsPerPage));
      setContAmbienteExistentes(res.length);
      setCurrentPageAmbienteExistentes(0);
      setSelectedRowAmbienteExistentes(null);

      await handlePerguntaNotAmbiente(res);
    } catch (err) {
      console.log(err);
    }
  };

  const handlePerguntaNotAmbiente = async (amb) => {
    try {
      const response = await ApiGet(
        '/v1/selectTabela/',
        {
          from: 'questionario',
          select: ['que_seqque', 'que_descri'],
          order: {
            column: 'que_descri',
            isAscending: true
          }
        },
        localStorage.getItem('sessionToken')
      );

      const qapSeqqueList = amb.map((itm) => itm.qap_seqque);

      const res = response.data.dados.rows.filter(
        (item) => !qapSeqqueList.includes(item.que_seqque)
      );

      setDataAmbienteNotPergunta(res);

      setFilteredDataAmbienteNotPergunta(
        res.slice(
          offsetAmbienteNotPergunta,
          offsetAmbienteNotPergunta + itemsPerPage
        )
      );
      setPageCountAmbienteNotPergunta(Math.ceil(res.length / itemsPerPage));
      setContAmbienteNotPergunta(res.length);
      setCurrentPageAmbienteNotPergunta(0);
      setSelectedRowAmbienteNotPergunta(null);
    } catch (err) {
      console.log(err);
    }
  };

  const handleEdit = async (body) => {
    try {
      const response = await ApiGet(
        '/v1/selectTabela/',
        {
          from: 'questionario_area_pergunta',
          select: [
            'qap_codare',
            'qap_codsar',
            'qap_seqque',
            '(SELECT are_descri from tab_area where are_codare = qap_codare) as are_descri',
            '(SELECT sar_descri from tab_sub_area where sar_codsar = qap_codsar) as sar_descri',
            '(SELECT que_descri from questionario where que_seqque = qap_seqque) as que_descri'
          ],
          groupBy:
            'qap_codare, qap_codsar, qap_seqque, are_descri, sar_descri, que_descri',
          where: {
            qap_codare: `${body.codigoArea || ''}`,
            qap_codsar: `${body.codigoSubarea || ''}`
          },
          order: {
            column: 'que_descri',
            isAscending: true
          }
        },
        localStorage.getItem('sessionToken')
      );
      setDataQuestionario(response.data.dados.rows);

      setFilteredDataQuestionario(
        response.data.dados.rows.slice(
          offsetQuestionario,
          offsetQuestionario + itemsPerPage
        )
      );
      setPageCountQuestionario(
        Math.ceil(response.data.dados.rows.length / itemsPerPage)
      );
      setCurrentPageQuestionario(0);
      setSelectedRowQuestionario(null);
      setContQuestionario(response.data.dados.rows.length);

      const response2 = await ApiGet(
        '/v1/selectTabela/',
        {
          from: 'pergunta_subarea',
          select: [
            'psa_codare',
            'psa_codsar',
            'psa_codper',
            'per_descri',
            'per_abrevi'
          ],
          groupBy: '',
          where: {
            psa_codsar: `${body.codigoSubarea || ''}`
          },
          order: {
            column:
              'psa_codare, psa_codsar, psa_codper, per_descri, per_abrevi',
            isAscending: true
          },
          join: ['left', 'tab_pergunta', 'psa_codper = per_codper']
        },
        localStorage.getItem('sessionToken')
      );
      setDataEscolherPergunta(response2.data.dados.rows);

      setFilteredDataEscolherPergunta(
        response2.data.dados.rows.slice(
          offsetEscolherPergunta,
          offsetEscolherPergunta + itemsPerPage
        )
      );
      setPageCountEscolherPergunta(
        Math.ceil(response2.data.dados.rows.length / itemsPerPage)
      );
      setCurrentPageEscolherPergunta(0);
      setSelectedRowEscolherPergunta(null);
      setContEscolherPergunta(response2.data.dados.rows.length);
    } catch (err) {
      console.log(err);
    }
  };

  const handleEditQuestionarioArea = async (body) => {
    try {
      const response = await ApiGet(
        '/v1/selectTabela/',
        {
          from: 'questionario_area_pergunta',
          select: [
            'qap_codare',
            'qap_codsar',
            'qap_seqque',
            'qap_codper',
            '(SELECT are_descri from tab_area where are_codare = qap_codare) as are_descri',
            '(SELECT sar_descri from tab_sub_area where sar_codsar = qap_codsar) as sar_descri',
            '(SELECT que_descri from questionario where que_seqque = qap_seqque) as que_descri',
            '(SELECT per_descri from tab_pergunta where per_codper = qap_codper) as per_descri'
          ],
          where: {
            qap_codare: `${body.codigoArea || ''}`,
            qap_seqque: `${body.codigoQuestionario || ''}`
          },
          order: {
            column: 'sar_descri, que_descri, per_descri',
            isAscending: true
          }
        },
        localStorage.getItem('sessionToken')
      );
      setDataQuestionarioAreaDados(response.data.dados.rows);

      setFilteredDataQuestionarioAreaDados(
        response.data.dados.rows.slice(
          offsetQuestionarioAreaDados,
          offsetQuestionarioAreaDados + itemsPerPage
        )
      );
      setPageCountQuestionarioAreaDados(
        Math.ceil(response.data.dados.rows.length / itemsPerPage)
      );
      setCurrentPageQuestionarioAreaDados(0);
      setSelectedRowQuestionarioAreaDados(null);
      setContQuestionarioAreaDados(response.data.dados.rows.length);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSearch = async (dataFiltro) => {
    try {
      const response = await ApiGet(
        '/v1/selectTabela/',
        {
          from: 'questionario_area_pergunta',
          select: [
            'qap_codare',
            'qap_codsar',
            '(SELECT are_descri from tab_area where are_codare = qap_codare) as are_descri',
            '(SELECT sar_descri from tab_sub_area where sar_codsar = qap_codsar) as sar_descri'
          ],
          groupBy: 'qap_codare, qap_codsar, are_descri, sar_descri',
          where: {
            qap_codare: `${props.dataFitro?.area || ''}`,
            qap_codsar: `${props.dataFiltro?.subarea || ''}`
          },
          order: {
            column: 'are_descri, sar_descri',
            isAscending: true
          }
        },
        localStorage.getItem('sessionToken')
      );
      setDataGridUnique(response.data.dados.rows);

      setDataGrid(await questionarioAreaPerguntaSelectUnique(dataFiltro));

      const response1 = await ApiGet(
        '/v1/selectTabela/',
        {
          from: 'questionario_area_pergunta',
          select: [
            'qap_codare',
            'qap_seqque',
            '(SELECT are_descri from tab_area where are_codare = qap_codare) as are_descri',
            '(SELECT que_descri from questionario where que_seqque = qap_seqque) as que_descri'
          ],
          groupBy: 'qap_codare, qap_seqque, are_descri, que_descri',
          where: {
            qap_codare: `${props.dataFitro?.area || ''}`,
            qap_seqque: `${props.dataFiltro?.questionario || ''}`
          },
          order: {
            column: 'are_descri, que_descri',
            isAscending: true
          }
        },
        localStorage.getItem('sessionToken')
      );
      setDataQuestionarioArea(response1.data.dados.rows);
      setContQuestionarioArea(response1.data.dados.rows.length);
    } catch (err) {
      console.log(err);
    }

    setOptionsArea(await areaSelect());
    setOptionsSubarea(await subareaSelect({}));
    setOptionsQuestionario(await ambienteSelect({}));
  };

  const handleQuestionarioNovo = async () => {
    const filter = optionsQuestionario.filter(
      (item) =>
        !dataGrid.some(
          (option) =>
            item.codigo === option.codigoQuestionario &&
            data.codigoSubarea === option.codigoSubarea
        )
    );

    setOptionsQuestionarioNovo(filter);
  };

  const handleQuestionarioNovCopia = async () => {
    const filter = optionsQuestionario.filter(
      (item) =>
        !dataGrid.some((option) => item.codigo === option.codigoQuestionario)
    );

    setOptionsQuestionarioNovoCopia(filter);
  };

  const handleCopiarQuestionario = async () => {
    try {
      const response = await ApiGet(
        '/v1/selectTabela/',
        {
          from: 'questionario_area_pergunta',
          select: [
            'qap_codare',
            'qap_codsar',
            'qap_seqque',
            'qap_codper',
            'qap_ordare',
            'qap_ordsar',
            'qap_ordper',
            'per_descri'
          ],
          where: {
            // qap_codare: `${data.codigoArea}`,
            // qap_codsar: `${data.codigoSubarea}`,
            qap_seqque: `${data.codigoQuestionario}`
          },
          join: ['left', 'tab_pergunta', 'qap_codper = per_codper']
        },
        localStorage.getItem('sessionToken')
      );
      const res = response.data.dados.rows;

      await questionarioAreaPerguntaCopia(data, res);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSort = (order, direction) => {
    let multiplier = direction === 'asc' ? 1 : -1;
    switch (order) {
      case 'codigoArea':
        setDataGridUnique(
          [...dataGridUnique].sort(
            (a, b) => multiplier * a.codigoArea.localeCompare(b.codigoArea)
          )
        );
        break;
      case 'codigoSubarea':
        setDataGridUnique(
          [...dataGridUnique].sort(
            (a, b) =>
              multiplier * a.codigoSubarea.localeCompare(b.codigoSubarea)
          )
        );
        break;
      case 'codigoQuestionario':
        setDataGridUnique(
          [...dataGridUnique].sort(
            (a, b) =>
              multiplier *
              a.codigoQuestionario.localeCompare(b.codigoQuestionario)
          )
        );
        break;
      case 'descricaoArea':
        setDataGridUnique(
          [...dataGridUnique].sort(
            (a, b) =>
              multiplier * a.descricaoArea.localeCompare(b.descricaoArea)
          )
        );
        break;
      case 'descricaoSubarea':
        setDataGridUnique(
          [...dataGridUnique].sort(
            (a, b) =>
              multiplier * a.descricaoSubarea.localeCompare(b.descricaoSubarea)
          )
        );
        break;
      case 'descricaoQuestionario':
        setDataGridUnique(
          [...dataGridUnique].sort(
            (a, b) =>
              multiplier *
              a.descricaoQuestionario.localeCompare(b.descricaoQuestionario)
          )
        );
        break;
      default:
        setDataGridUnique([...dataGridUnique]);
        break;
    }
  };

  const handleSortQuestionarioArea = (order, direction) => {
    let multiplier = direction === 'asc' ? 1 : -1;
    switch (order) {
      case 'are_descri':
        setDataQuestionarioArea(
          [...dataQuestionarioArea].sort(
            (a, b) => multiplier * a.are_descri.localeCompare(b.are_descri)
          )
        );
        break;
      case 'qap_codare':
        setDataQuestionarioArea(
          [...dataQuestionarioArea].sort(
            (a, b) => multiplier * a.qap_codare.localeCompare(b.qap_codare)
          )
        );
        break;
      case 'qap_seqque':
        setDataQuestionarioArea(
          [...dataQuestionarioArea].sort(
            (a, b) => multiplier * a.qap_seqque.localeCompare(b.qap_seqque)
          )
        );
        break;
      case 'que_descri':
        setDataQuestionarioArea(
          [...dataQuestionarioArea].sort(
            (a, b) => multiplier * a.que_descri.localeCompare(b.que_descri)
          )
        );
        break;
      default:
        setDataQuestionarioArea([...dataQuestionarioArea]);
        break;
    }
  };

  const handleSortPergunta = (order, direction) => {
    let multiplier = direction === 'asc' ? 1 : -1;
    switch (order) {
      case 'psa_codper':
        setDataPergunta(
          [...dataPergunta].sort(
            (a, b) => multiplier * a.psa_codper.localeCompare(b.psa_codper)
          )
        );
        break;
      case 'per_descri':
        setDataPergunta(
          [...dataPergunta].sort(
            (a, b) => multiplier * a.per_descri.localeCompare(b.per_descri)
          )
        );
        break;
      case 'per_abrevi':
        setDataPergunta(
          [...dataPergunta].sort(
            (a, b) => multiplier * a.per_abrevi.localeCompare(b.per_abrevi)
          )
        );
        break;
      default:
        setDataPergunta([...dataPergunta]);
        break;
    }
  };

  const handleSave = async (body) => {
    await questionarioAreaPerguntaPost(body);
    await handlePerguntaExistentes();
    await handleAmbienteExistentes();
  };

  const verificarInsert = async (cond) => {
    if (cond) {
      const response1 = await ApiGet(
        '/v1/selectTabela/',
        {
          from: 'questionario_area_pergunta',
          select: [
            'qap_codare',
            'qap_codsar',
            // 'qap_seqque',
            // 'qap_codper',
            'sar_descri'
          ],
          where: {
            qap_codare: `${data.codigoArea}`,
            // qap_codsar: `${data.codigoSubarea}`,
            qap_seqque: `${data.codigoQuestionario}`
          },
          groupBy: 'qap_codare, qap_codsar, sar_descri',
          join: ['left', 'tab_sub_area', 'qap_codsar = sar_codsar']
        },
        localStorage.getItem('sessionToken')
      );

      const response2 = await ApiGet(
        '/v1/selectTabela/',
        {
          from: 'tab_sub_area',
          select: ['sar_codsar', 'sar_descri'],
          where: { sar_codare: `${data.codigoArea}` },
          order: {
            column: 'sar_descri',
            isAscending: true
          },
          groupBy: 'sar_codsar, sar_descri'
        },
        localStorage.getItem('sessionToken')
      );

      const res1 = response1.data.dados.rows;
      const res2 = response2.data.dados.rows;

      const filteredRes2 = res2.filter((item2) => {
        return !res1.some((item1) => item1.sar_descri === item2.sar_descri);
      });

      if (filteredRes2.length > 0) {
        await Swal.fire({
          title: 'Falta algumas subareas para este ambiente!',
          html: `Ambiente: ${data.questionario}<br>Subarea que falta:<br>
          ${filteredRes2.map((item) => `${item.sar_descri}<br>`)}`,
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok'
        });

        handleQuestionarioNovo();
        handleQuestionarioNovCopia();

        setData({
          ...data,
          codigoSubarea: '',
          subarea: '',
          perguntas: '',
          perguntasDescricao: ''
        });
      } else {
        await Swal.fire({
          title: 'Todas as subareas já foram preenchidas!',
          icon: 'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok'
        });

        props.setTabReturn(0);
        setActiveTab(1);
        setData({});
      }
    }
  };

  useEffect(() => {
    if (props.tabReturn === 1 && props.activeTab === 6) {
      handlePerguntaExistentes();
    }

    if (props.activeTab === 6) {
      handleSearch(props.dataFiltro);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeTab, props.filter]);

  useEffect(() => {
    if (dataGridUnique != undefined) {
      setFilteredData(dataGridUnique.slice(offset, offset + itemsPerPage));
      setPageCount(Math.ceil(dataGridUnique.length / itemsPerPage));
      setCurrentPage(0);
      setSelectedRow(null);
    }
    // eslint-disable-next-line
  }, [dataGridUnique]);

  useEffect(() => {
    if (dataGridUnique !== undefined) {
      setFilteredData(dataGridUnique.slice(offset, offset + itemsPerPage));
    }
    // eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    if (dataQuestionarioArea != undefined) {
      setFilteredDataQuestionarioArea(
        dataQuestionarioArea.slice(
          offsetQuestionarioArea,
          offsetQuestionarioArea + itemsPerPage
        )
      );
      setPageCountQuestionarioArea(
        Math.ceil(dataQuestionarioArea.length / itemsPerPage)
      );
      setCurrentPageQuestionarioArea(0);
      setSelectedRowQuestionarioArea(null);
    }
    // eslint-disable-next-line
  }, [dataQuestionarioArea]);

  useEffect(() => {
    if (dataQuestionarioArea !== undefined) {
      setFilteredDataQuestionarioArea(
        dataQuestionarioArea.slice(
          offsetQuestionarioArea,
          offsetQuestionarioArea + itemsPerPage
        )
      );
    }
    // eslint-disable-next-line
  }, [currentPageQuestionarioArea]);

  useEffect(() => {
    if (activeTabCadastro === 3) {
      setFilteredDataQuestionario(
        dataQuestionario.slice(
          offsetQuestionario,
          offsetQuestionario + itemsPerPage
        )
      );
    }
    // eslint-disable-next-line
  }, [currentPageQuestionario]);

  useEffect(() => {
    if (activeTabCadastro === 3) {
      setFilteredDataEscolherPergunta(
        dataEscolherPergunta.slice(
          offsetEscolherPergunta,
          offsetEscolherPergunta + itemsPerPage
        )
      );
    }
    // eslint-disable-next-line
  }, [currentPageEscolherPergunta]);

  const isArea =
    data.codigoArea !== '' &&
    data.codigoArea !== null &&
    data.codigoArea !== undefined;
  const isSubarea =
    data.codigoSubarea !== '' &&
    data.codigoSubarea !== null &&
    data.codigoSubarea !== undefined;
  const isQuestionario =
    data.codigoQuestionario !== '' &&
    data.codigoQuestionario !== null &&
    data.codigoQuestionario !== undefined;
  const isQuestionarioNovo =
    data.codigoQuestionarioNovo !== '' &&
    data.codigoQuestionarioNovo !== null &&
    data.codigoQuestionarioNovo !== undefined;

  useEffect(() => {
    if (activeTab === 2 && isArea && isSubarea && isQuestionario) {
      const hasPerguntasChanged = prevPerguntasRef.current !== data.perguntas;
      prevPerguntasRef.current = data.perguntas;

      const hasAbreviaturaChanged =
        prevAbreviaturaRef.current !== data.abreviatura;
      prevAbreviaturaRef.current = data.abreviatura;

      const hasDescricaoChanged =
        prevDescricaoRef.current !== data.descricaoPergunta;
      prevDescricaoRef.current = data.descricaoPergunta;

      if (
        !hasPerguntasChanged &&
        !hasAbreviaturaChanged &&
        !hasDescricaoChanged
      ) {
        const fetch = async () => {
          await handlePerguntaExistentes();
        };
        fetch();
      }
    }
    // eslint-disable-next-line
  }, [activeTab, data, activeTabCadastro]);

  useEffect(() => {
    if (activeTab === 2) {
      const hasPerguntasChanged = prevPerguntasRef.current !== data.perguntas;
      prevPerguntasRef.current = data.perguntas;

      const hasAbreviaturaChanged =
        prevAbreviaturaRef.current !== data.abreviatura;
      prevAbreviaturaRef.current = data.abreviatura;

      const hasDescricaoChanged =
        prevDescricaoRef.current !== data.descricaoPergunta;
      prevDescricaoRef.current = data.descricaoPergunta;

      if (
        activeTab === 2 &&
        !hasPerguntasChanged &&
        !hasAbreviaturaChanged &&
        !hasDescricaoChanged
      ) {
        handleQuestionarioNovo();
        handleQuestionarioNovCopia();
      }
    }
  }, [activeTab, data]);

  useEffect(() => {
    if (activeTab === 2 && isArea && isSubarea && isQuestionario) {
      const fetch = async () => {
        setFilteredDataPerguntaExistentes(
          dataPerguntasExistentes.slice(
            offsetPerguntaExistentes,
            offsetPerguntaExistentes + itemsPerPage
          )
        );
      };
      fetch();
    }
    // eslint-disable-next-line
  }, [currentPagePerguntaExistentes]);

  useEffect(() => {
    if (activeTab === 2 && isArea && isSubarea) {
      const fetch = async () => {
        setFilteredDataAmbienteExistentes(
          dataAmbienteExistentes.slice(
            offsetAmbienteExistentes,
            offsetAmbienteExistentes + itemsPerPage
          )
        );
      };
      fetch();
    }
    // eslint-disable-next-line
  }, [currentPageAmbienteExistentes]);

  useEffect(() => {
    if (activeTab === 2 && isArea && isSubarea) {
      const fetch = async () => {
        setFilteredDataAmbienteNotPergunta(
          dataAmbienteNotPergunta.slice(
            offsetAmbienteNotPergunta,
            offsetAmbienteNotPergunta + itemsPerPage
          )
        );
      };
      fetch();
    }
    // eslint-disable-next-line
  }, [currentPageAmbienteNotPergunta]);

  useEffect(() => {
    if (activeTab === 2) {
      const fetch = async () => {
        setFilteredDataQuestionarioAreaDados(
          dataQuestionarioAreaDados.slice(
            offsetQuestionarioAreaDados,
            offsetQuestionarioAreaDados + itemsPerPage
          )
        );
      };
      fetch();
    }
    // eslint-disable-next-line
  }, [currentPageQuestionarioAreaDados]);

  useEffect(() => {
    if (activeTab === 2 && isArea && isSubarea && isQuestionario) {
      const fetch = async () => {
        setFilteredDataQuestionarioArea(
          dataPerguntasExistentes.slice(
            offsetQuestionarioArea,
            offsetQuestionarioArea + itemsPerPage
          )
        );
      };
      fetch();
    }
    // eslint-disable-next-line
  }, [currentPageQuestionarioArea]);

  useEffect(() => {
    if (activeTab === 2 && isArea && isSubarea && isQuestionario) {
      const fetch = async () => {
        setFilteredDataPergunta(() => {
          const filter = dataPergunta.filter(
            (item) =>
              !dataPerguntasExistentes.some(
                (option) => item.psa_codper === option.qap_codper
              )
          );

          return filter.slice(offsetPergunta, offsetPergunta + itemsPerPage);
        });
      };
      fetch();
    }
    // eslint-disable-next-line
  }, [currentPagePergunta]);

  useEffect(() => {
    if (dataPergunta != undefined) {
      setFilteredDataPergunta(
        dataPergunta.slice(offsetPergunta, offsetPergunta + itemsPerPage)
      );
      setPageCount(Math.ceil(dataPergunta.length / itemsPerPage));
      setCurrentPagePergunta(0);
      setSelectedRowPergunta(null);
    }
    // eslint-disable-next-line
  }, [dataPergunta]);

  useEffect(() => {
    if (props.tabInsert === 6) {
      const data = new Date();
      const ano = data.getFullYear();
      const mes = String(data.getMonth() + 1).padStart(2, '0');
      const dia = String(data.getDate()).padStart(2, '0');

      const hora = data.getHours();
      const minuto = String(data.getMinutes()).padStart(2, '0');
      const segundo = String(data.getSeconds()).padStart(2, '0');

      setData({
        ...data,
        usuario: localStorage.getItem('sessionUsuario'),
        dataInclusao: `${ano}-${mes}-${dia}`,
        horaInclusao: `${hora}:${minuto}:${segundo}`
      });

      setDataPergunta([]);
      setPageCountPergunta(0);
      setCurrentPagePergunta(0);
      setSelectedRowPergunta(null);
      setFilteredDataPergunta([]);
      setContPergunta([]);

      setDataPerguntasExistentes([]);
      setPageCountPerguntaExistentes(0);
      setCurrentPagePerguntaExistentes(0);
      setSelectedRowPerguntaExistentes(null);
      setFilteredDataPerguntaExistentes([]);
      setFilteredDataQuestionarioAreaDados([]);
      setContPerguntaExistentes([]);

      props.setTitle(`${props.title} - Inserir`);
      props.setNvAcesso('insert');
      props.setTabReturn(1);
      setInsertOrUpdate('insert');
      setActiveTabCadastro(1);
      props.setIsAdicionarPergunta(false);
      setActiveTab(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tabInsert]);

  useEffect(() => {
    if (props.tabReturn === 1) {
      if (props.tabInsert === 6) {
        setActiveTabCadastro(1);
      } else {
        setActiveTabCadastro(3);
        const texto = props.title.split(' | Ambiente');
        props.setTitle(texto[0]);
      }
    } else if (props.tabReturn === 0) {
      setActiveTab(1);
      setData({});
    } else if (props.tabReturn === 3) {
      setActiveTabCadastro(3);
      setActiveTabCadastro(3);
      const texto = props.title.split(' | Ambiente');
      props.setTitle(texto[0]);
    }
  }, [props.tabReturn]);

  useEffect(() => {
    if (props.tabUpdate === 6) {
      const fetch = async () => {
        if (activeTabCadastro === 1) {
          if (!isArea) {
            alert('Área não informada');
            props.setTabUpdate(0);
            return;
          } else if (!isSubarea) {
            alert('Subarea não informada');
            props.setTabUpdate(0);
            return;
          } else if (!isQuestionario) {
            alert('Questionário não informado');
            props.setTabUpdate(0);
            return;
          } else {
            const response = await questionarioAreaPerguntaPostVarios(data);
            await verificarInsert(response);
            await handleSearch();
            const texto = props.title.split(' - ');
            props.setTitle(texto[0]);
            props.setTabUpdate(0);
          }
        } else if (activeTabCadastro === 2) {
          if (!isQuestionario) {
            alert('Questionário não informado');
            props.setTabUpdate(0);
            return;
          } else if (!isQuestionarioNovo) {
            alert('Questionário novo não informado');
            props.setTabUpdate(0);
            return;
          } else {
            await handleCopiarQuestionario();
            await handleSearch();
            const texto = props.title.split(' - ');
            props.setTitle(texto[0]);
            props.setTabUpdate(0);
            props.setCopy(0);
            props.setTabReturn(0);
            setActiveTab(1);
            setData({});
          }
        }
      };
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tabUpdate]);

  return (
    <div>
      <div>
        <div className="nav nav-tabs">
          <p style={{ display: 'none' }}>{/* Tabela */}</p>
          <p style={{ display: 'none' }}>{/* Insert */}</p>
        </div>

        <div className="tab-content">
          <div
            className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}
            id="tab1"
          >
            <div className="nav nav-tabs">
              <p
                className={`nav-link ${
                  activeTabNavegacao === 1 ? 'text-primary active' : 'text-dark'
                }`}
                onClick={() => {
                  setActiveTabNavegacao(1);
                }}
              >
                Perguntas do Ambiente por Subarea
              </p>
              <p
                className={`nav-link ${
                  activeTabNavegacao === 2 ? 'text-primary active' : 'text-dark'
                }`}
                onClick={() => {
                  setActiveTabNavegacao(2);
                }}
              >
                Perguntas do Ambiente por Área
              </p>
            </div>

            <div className="tab-content">
              <div
                className={`tab-pane ${
                  activeTabNavegacao === 1 ? 'active' : ''
                }`}
                id="tab1"
              >
                <Grid
                  filteredData={filteredData}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageCount={pageCount}
                  displayedPageNumbers={displayedPageNumbers}
                  setActiveTab={setActiveTab}
                  data={data}
                  setData={setData}
                  dataGrid={dataGridUnique}
                  setInsertOrUpdate={setInsertOrUpdate}
                  handleSearch={handleSearch}
                  tamTexto={props.tamTexto}
                  tamSubTexto={props.tamSubTexto}
                  setTabReturn={props.setTabReturn}
                  setNvAcesso={props.setNvAcesso}
                  handleSort={handleSort}
                  optionsArea={optionsArea}
                  optionsSubarea={optionsSubarea}
                  optionsQuestionario={optionsQuestionario}
                  setTitle={props.setTitle}
                  title={props.title}
                  setActiveTabCadastro={setActiveTabCadastro}
                  setIsAdicionarPergunta={props.setIsAdicionarPergunta}
                  handleEdit={handleEdit}
                  setIsQuestionarioArea={setIsQuestionarioArea}
                />
              </div>

              <div
                className={`tab-pane ${
                  activeTabNavegacao === 2 ? 'active' : ''
                }`}
                id="tab2"
              >
                <GridQuestionarioArea
                  filteredData={filteredDataQuestionarioArea}
                  selectedRow={selectedRowQuestionarioArea}
                  setSelectedRow={setSelectedRowQuestionarioArea}
                  currentPage={currentPageQuestionarioArea}
                  setCurrentPage={setCurrentPageQuestionarioArea}
                  pageCount={pageCountQuestionarioArea}
                  displayedPageNumbers={displayedPageNumbersQuestionarioArea}
                  contQuestionarioArea={contQuestionarioArea}
                  setActiveTab={setActiveTab}
                  data={data}
                  setData={setData}
                  dataGrid={dataGridUnique}
                  setInsertOrUpdate={setInsertOrUpdate}
                  handleSearch={handleSearch}
                  tamTexto={props.tamTexto}
                  tamSubTexto={props.tamSubTexto}
                  setTabReturn={props.setTabReturn}
                  setNvAcesso={props.setNvAcesso}
                  handleSort={handleSortQuestionarioArea}
                  optionsArea={optionsArea}
                  optionsSubarea={optionsSubarea}
                  optionsQuestionario={optionsQuestionario}
                  setTitle={props.setTitle}
                  title={props.title}
                  setActiveTabCadastro={setActiveTabCadastro}
                  setIsAdicionarPergunta={props.setIsAdicionarPergunta}
                  handleEdit={handleEditQuestionarioArea}
                  setIsQuestionarioArea={setIsQuestionarioArea}
                />
              </div>
            </div>
          </div>
          <div
            className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}
            id="tab2"
          >
            <Cadastro
              data={data}
              setData={setData}
              insertOrUpdate={insertOrUpdate}
              optionsArea={optionsArea}
              optionsSubarea={optionsSubarea}
              optionsQuestionario={optionsQuestionario}
              activeTabCadastro={activeTabCadastro}
              setActiveTabCadastro={setActiveTabCadastro}
              tamTexto={props.tamTexto}
              tamSubTexto={props.tamSubTexto}
              filteredData={filteredDataPergunta}
              selectedRow={selectedRowPergunta}
              setSelectedRow={setSelectedRowPergunta}
              currentPage={currentPagePergunta}
              setCurrentPage={setCurrentPagePergunta}
              pageCount={pageCountPergunta}
              displayedPageNumbers={displayedPageNumbersPergunta}
              filteredDataPerguntaExistentes={filteredDataPerguntaExistentes}
              selectedRowPerguntaExistentes={selectedRowPerguntaExistentes}
              setSelectedRowPerguntaExistentes={
                setSelectedRowPerguntaExistentes
              }
              currentPagePerguntaExistentes={currentPagePerguntaExistentes}
              setCurrentPagePerguntaExistentes={
                setCurrentPagePerguntaExistentes
              }
              pageCountPerguntaExistentes={pageCountPerguntaExistentes}
              displayedPageNumbersPerguntaExistentes={
                displayedPageNumbersPerguntaExistentes
              }
              handleSave={handleSave}
              contPergunta={contPergunta}
              contPerguntaExistentes={contPerguntaExistentes}
              optionsQuestionarioNovo={optionsQuestionarioNovo}
              setCopy={props.setCopy}
              handlePerguntaExistentes={handlePerguntaExistentes}
              setIsAdicionarPergunta={props.setIsAdicionarPergunta}
              //
              filteredDataAmbienteExistentes={filteredDataAmbienteExistentes}
              selectedRowAmbienteExistentes={selectedRowAmbienteExistentes}
              setSelectedRowAmbienteExistentes={
                setSelectedRowAmbienteExistentes
              }
              currentPageAmbienteExistentes={currentPageAmbienteExistentes}
              setCurrentPageAmbienteExistentes={
                setCurrentPageAmbienteExistentes
              }
              pageCountAmbienteExistentes={pageCountAmbienteExistentes}
              displayedPageNumbersAmbienteExistentes={
                displayedPageNumbersAmbienteExistentes
              }
              contAmbienteExistentes={contAmbienteExistentes}
              filteredDataAmbienteNotPergunta={filteredDataAmbienteNotPergunta}
              selectedRowAmbienteNotPergunta={selectedRowAmbienteNotPergunta}
              setSelectedRowAmbienteNotPergunta={
                setSelectedRowAmbienteNotPergunta
              }
              currentPageAmbienteNotPergunta={currentPageAmbienteNotPergunta}
              setCurrentPageAmbienteNotPergunta={
                setCurrentPageAmbienteNotPergunta
              }
              pageCountAmbienteNotPergunta={pageCountAmbienteNotPergunta}
              displayedPageNumbersAmbienteNotPergunta={
                displayedPageNumbersAmbienteNotPergunta
              }
              contAmbienteNotPergunta={contAmbienteNotPergunta}
              //
              filteredDataQuestionario={filteredDataQuestionario}
              selectedRowQuestionario={selectedRowQuestionario}
              setSelectedRowQuestionario={setSelectedRowQuestionario}
              currentPageQuestionario={currentPageQuestionario}
              setCurrentPageQuestionario={setCurrentPageQuestionario}
              pageCountQuestionario={pageCountQuestionario}
              displayedPageNumbersQuestionario={
                displayedPageNumbersQuestionario
              }
              contQuestionario={contQuestionario}
              dataEscolherPergunta={dataEscolherPergunta}
              setDataEscolherPergunta={setDataEscolherPergunta}
              filteredDataEscolherPergunta={filteredDataEscolherPergunta}
              selectedRowEscolherPergunta={selectedRowEscolherPergunta}
              setSelectedRowEscolherPergunta={setSelectedRowEscolherPergunta}
              currentPageEscolherPergunta={currentPageEscolherPergunta}
              setCurrentPageEscolherPergunta={setCurrentPageEscolherPergunta}
              pageCountEscolherPergunta={pageCountEscolherPergunta}
              displayedPageNumbersEscolherPergunta={
                displayedPageNumbersEscolherPergunta
              }
              contEscolherPergunta={contEscolherPergunta}
              //
              filteredDataQuestionarioArea={filteredDataQuestionarioAreaDados}
              selectedRowQuestionarioArea={selectedRowQuestionarioAreaDados}
              setSelectedRowQuestionarioArea={
                setSelectedRowQuestionarioAreaDados
              }
              currentPageQuestionarioArea={currentPageQuestionarioAreaDados}
              setCurrentPageQuestionarioArea={
                setCurrentPageQuestionarioAreaDados
              }
              pageCountQuestionarioArea={pageCountQuestionarioAreaDados}
              displayedPageNumbersQuestionarioArea={
                displayedPageNumbersQuestionarioAreaDados
              }
              contQuestionarioArea={contQuestionarioAreaDados}
              optionsQuestionarioNovoCopia={optionsQuestionarioNovoCopia}
              setTitle={props.setTitle}
              title={props.title}
              setTabReturn={props.setTabReturn}
              handleSortPergunta={handleSortPergunta}
              isQuestionarioArea={isQuestionarioArea}
              handleEditQuestionarioArea={handleEditQuestionarioArea}
              setActiveTabQuesPer={setActiveTabQuesPer}
              activeTabQuesPer={activeTabQuesPer}
              setIsPerguntaOrAmbiente={setIsPerguntaOrAmbiente}
              isPerguntaOrAmbiente={isPerguntaOrAmbiente}
              handleAmbienteExistentes={handleAmbienteExistentes}
              setPerguntaSelecionada={setPerguntaSelecionada}
              perguntaSelecionada={perguntaSelecionada}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
