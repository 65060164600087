export const Cadastro = ({
  data,
  setData,
  insertOrUpdate,
  optionsArea,
  optionsQuestionario
}) => {
  return (
    <div className="row">
      <div className="col-12 col-lg-6">
        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Área</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <select
              className="form-select form-select-sm border border-dark"
              name="res_tipapa"
              value={data?.qar_codare || ''}
              onChange={(e) => setData({ ...data, qar_codare: e.target.value })}
              disabled={insertOrUpdate === 'read' && true}
            >
              <option defaultValue></option>
              {optionsArea
                ?.slice()
                .sort((a, b) => a.are_descri.localeCompare(b.are_descri))
                .map((option, index) => (
                  <option key={index} value={option.are_codare}>
                    {option.are_descri}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className="col-12 row">
          <div className="col-12">
            <span className="col-form-label">Questionário</span>
          </div>
        </div>
        <div className="col-12 row">
          <div className="col-12">
            <select
              className="form-select form-select-sm border border-dark"
              name="res_tipapa"
              value={data?.qar_seqque || ''}
              onChange={(e) => setData({ ...data, qar_seqque: e.target.value })}
              disabled={insertOrUpdate === 'read' && true}
            >
              <option defaultValue></option>
              {optionsQuestionario
                ?.slice()
                .sort((a, b) => a.descricao.localeCompare(b.descricao))
                .map((option, index) => (
                  <option key={index} value={option.codigo}>
                    {option.descricao}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};
